import { ofArray, toArray, item as item_1, length, singleton, isEmpty } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { empty, singleton as singleton_1, collect, delay, toList } from "./fable_modules/fable-library-js.4.19.3/Seq.js";
import { rangeDouble } from "./fable_modules/fable-library-js.4.19.3/Range.js";
import { concat, mapIndexed, splitAt, choose, item as item_2 } from "./fable_modules/fable-library-js.4.19.3/Array.js";
import { some } from "./fable_modules/fable-library-js.4.19.3/Option.js";

export function insertAt(index, newEl, input) {
    if (isEmpty(input)) {
        return singleton(newEl);
    }
    else {
        const list = input;
        const indexes = (length(list) - 1) | 0;
        return toList(delay(() => collect((i) => ((index === i) ? [newEl, item_1(i, list)] : (((index > i) && (i === indexes)) ? [item_1(i, list), newEl] : singleton_1(item_1(i, list)))), toList(rangeDouble(0, 1, indexes)))));
    }
}

export function replaceAt(index, newEl, input) {
    const indexes = (length(input) - 1) | 0;
    return toList(delay(() => collect((i) => ((index === i) ? singleton_1(newEl) : singleton_1(item_1(i, input))), toList(rangeDouble(0, 1, indexes)))));
}

export function removeAt(index, input) {
    const indexes = (length(input) - 1) | 0;
    return toList(delay(() => collect((i) => ((index !== i) ? singleton_1(item_1(i, input)) : empty()), toList(rangeDouble(0, 1, indexes)))));
}

export function moveAt(from, into, input) {
    return insertAt((into < 0) ? 0 : into, item_1(from, input), removeAt(from, input));
}

function add1(x) {
    return x + 1;
}

export function reorder(oldIndex, newIndex, itemsList) {
    let patternInput;
    const items = toArray(itemsList);
    const itemTobeMoved = item_2(oldIndex, items);
    item_2(newIndex, items);
    return ofArray(choose((x) => x, (patternInput = splitAt((newIndex > oldIndex) ? (newIndex + 1) : newIndex, mapIndexed((index, item) => {
        if (index === oldIndex) {
            return undefined;
        }
        else {
            return some(item);
        }
    }, items)), concat([patternInput[0], [some(itemTobeMoved)], patternInput[1]]))));
}

