import { Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { defaultArg, unwrap, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import * as react from "react";
import { Tooltip } from "@stenajs-webui/tooltip";

export class TooltipVariant extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Standard", "Info", "Warning", "Error", "Outlined"];
    }
}

export function TooltipVariant_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Elements.TooltipVariant", [], TooltipVariant, () => [[], [], [], [], []]);
}

export function TooltipVariantModule_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "info";
        case 2:
            return "warning";
        case 3:
            return "error";
        case 4:
            return "outlined";
        default:
            return "standard";
    }
}

export function TooltipModule_View(p) {
    let props;
    const placement = map((x) => x, FSharpMap__TryFind(p, "placement"));
    const label = map((x_1) => x_1, FSharpMap__TryFind(p, "label"));
    const variant = map(TooltipVariantModule_value, map((x_2) => x_2, FSharpMap__TryFind(p, "variant")));
    props = {
        disablePadding: map((x_3) => x_3, FSharpMap__TryFind(p, "disablePadding")),
        label: label,
        placement: placement,
        variant: unwrap(variant),
    };
    const children = defaultArg(map((x_4) => x_4, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Tooltip, props, ...children);
}

