import React from "react";
import * as react from "react";
import { map, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Space } from "@stenajs-webui/core";

export function SpaceModule_View(spaceModule_ViewInputProps) {
    const p = spaceModule_ViewInputProps.p;
    const props = {};
    const children = defaultArg(map((x) => x, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Space, props, ...children);
}

