import React from "react";
import * as react from "react";
import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Box } from "@stenajs-webui/core";

export function BoxModule_View(boxModule_ViewInputProps) {
    const p = boxModule_ViewInputProps.p;
    let props;
    const shadow = map((x) => x, FSharpMap__TryFind(p, "shadow"));
    const background = map((x_1) => x_1, FSharpMap__TryFind(p, "background"));
    const spacing = map((x_2) => x_2, FSharpMap__TryFind(p, "spacing"));
    const indent = map((x_3) => x_3, FSharpMap__TryFind(p, "indent"));
    props = {
        background: background,
        height: map((x_4) => x_4, FSharpMap__TryFind(p, "height")),
        indent: indent,
        shadow: shadow,
        spacing: spacing,
    };
    const children = defaultArg(map((x_5) => x_5, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Box, props, ...children);
}

