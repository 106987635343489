import React, { Component } from "react";
import { useMap } from "react-leaflet";
import L, { LeafletMouseEvent, Map } from "leaflet";

class Legend extends React.Component {
    legendDiv;
    createLegendControl() {
        const MapHelp = L.Control.extend({
            onAdd: (map) => {
                const legendDiv = L.DomUtil.create("div", "legend");
                this.legendDiv = legendDiv;
                legendDiv.innerHTML = this.props.html;
                return legendDiv;
            }
        });
        return new MapHelp({ position: this.props.position });
    }

    componentDidMount() {
        const { map } = this.props;
        const control = this.createLegendControl();
        control.addTo(map);
    }

    componentWillUnmount() {
        this.legendDiv.remove();
    }

    render() {
        return null;
    }
}

function withMap(Component) {
    return function WrappedComponent(props) {
        const map = useMap();
        return <Component {...props} map={map} />;
    };
}

export default withMap(Legend);