import React, { Component } from "react";
import {useMapEvents} from "react-leaflet";

function MapEventsComponent(props) {
    const map = useMapEvents({
        click: (args) => {
            props.onClick (args.latlng.lat, args.latlng.lng)
        }
    })
    return null
}

function withEvents() {
    return function WrappedComponent(props) {
        return <MapEventsComponent {...props} />;
    };
}

export default withEvents();