import { Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import React from "react";
import * as react from "react";
import { unwrap, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { FlatButton, SecondaryButton, PrimaryButton } from "@stenajs-webui/elements";

export class ButtonVariant extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Normal", "Danger", "Success"];
    }
}

export function ButtonVariant_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Elements.ButtonVariant", [], ButtonVariant, () => [[], [], []]);
}

export function ButtonVariantModule_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "danger";
        case 2:
            return "success";
        default:
            return "normal";
    }
}

export class ButtonSize extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Large", "Medium", "Small"];
    }
}

export function ButtonSize_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Elements.ButtonSize", [], ButtonSize, () => [[], [], []]);
}

export function ButtonSizeModule_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "medium";
        case 2:
            return "small";
        default:
            return "large";
    }
}

export function ButtonBase_ViewPrimary(buttonBase_ViewPrimaryInputProps) {
    const p = buttonBase_ViewPrimaryInputProps.p;
    let props;
    const id = map((x) => x, FSharpMap__TryFind(p, "id"));
    const label = map((x_1) => x_1, FSharpMap__TryFind(p, "label"));
    const loadingLabel = map((x_2) => x_2, FSharpMap__TryFind(p, "loadingLabel"));
    const loading = map((x_3) => x_3, FSharpMap__TryFind(p, "loading"));
    const success = map((x_4) => x_4, FSharpMap__TryFind(p, "success"));
    const successLabel = map((x_5) => x_5, FSharpMap__TryFind(p, "successLabel"));
    const leftIcon = map((x_6) => x_6, FSharpMap__TryFind(p, "leftIcon"));
    const left = map((x_7) => x_7, FSharpMap__TryFind(p, "left"));
    const rightIcon = map((x_8) => x_8, FSharpMap__TryFind(p, "rightIcon"));
    const right = map((x_9) => x_9, FSharpMap__TryFind(p, "right"));
    const size = map(ButtonSizeModule_value, map((x_10) => x_10, FSharpMap__TryFind(p, "size")));
    const variant = map(ButtonVariantModule_value, map((x_11) => x_11, FSharpMap__TryFind(p, "variant")));
    const onClick = map((x_12) => x_12, FSharpMap__TryFind(p, "onClick"));
    props = {
        disabled: map((x_13) => x_13, FSharpMap__TryFind(p, "disabled")),
        id: id,
        label: label,
        left: left,
        leftIcon: leftIcon,
        loading: loading,
        loadingLabel: loadingLabel,
        onClick: onClick,
        right: right,
        rightIcon: rightIcon,
        size: unwrap(size),
        success: success,
        successLabel: successLabel,
        variant: unwrap(variant),
    };
    return react.createElement(PrimaryButton, props);
}

export function ButtonBase_ViewSecondary(p) {
    let props;
    const id = map((x) => x, FSharpMap__TryFind(p, "id"));
    const label = map((x_1) => x_1, FSharpMap__TryFind(p, "label"));
    const loadingLabel = map((x_2) => x_2, FSharpMap__TryFind(p, "loadingLabel"));
    const loading = map((x_3) => x_3, FSharpMap__TryFind(p, "loading"));
    const success = map((x_4) => x_4, FSharpMap__TryFind(p, "success"));
    const successLabel = map((x_5) => x_5, FSharpMap__TryFind(p, "successLabel"));
    const leftIcon = map((x_6) => x_6, FSharpMap__TryFind(p, "leftIcon"));
    const left = map((x_7) => x_7, FSharpMap__TryFind(p, "left"));
    const rightIcon = map((x_8) => x_8, FSharpMap__TryFind(p, "rightIcon"));
    const right = map((x_9) => x_9, FSharpMap__TryFind(p, "right"));
    const size = map(ButtonSizeModule_value, map((x_10) => x_10, FSharpMap__TryFind(p, "size")));
    const variant = map(ButtonVariantModule_value, map((x_11) => x_11, FSharpMap__TryFind(p, "variant")));
    props = {
        id: id,
        label: label,
        left: left,
        leftIcon: leftIcon,
        loading: loading,
        loadingLabel: loadingLabel,
        onClick: map((x_12) => x_12, FSharpMap__TryFind(p, "onClick")),
        right: right,
        rightIcon: rightIcon,
        size: unwrap(size),
        success: success,
        successLabel: successLabel,
        variant: unwrap(variant),
    };
    return react.createElement(SecondaryButton, props);
}

export function ButtonBase_ViewFlat(p) {
    let props;
    const id = map((x) => x, FSharpMap__TryFind(p, "id"));
    const label = map((x_1) => x_1, FSharpMap__TryFind(p, "label"));
    const loadingLabel = map((x_2) => x_2, FSharpMap__TryFind(p, "loadingLabel"));
    const loading = map((x_3) => x_3, FSharpMap__TryFind(p, "loading"));
    const success = map((x_4) => x_4, FSharpMap__TryFind(p, "success"));
    const successLabel = map((x_5) => x_5, FSharpMap__TryFind(p, "successLabel"));
    const leftIcon = map((x_6) => x_6, FSharpMap__TryFind(p, "leftIcon"));
    const left = map((x_7) => x_7, FSharpMap__TryFind(p, "left"));
    const rightIcon = map((x_8) => x_8, FSharpMap__TryFind(p, "rightIcon"));
    const right = map((x_9) => x_9, FSharpMap__TryFind(p, "right"));
    const size = map(ButtonSizeModule_value, map((x_10) => x_10, FSharpMap__TryFind(p, "size")));
    const variant = map(ButtonVariantModule_value, map((x_11) => x_11, FSharpMap__TryFind(p, "variant")));
    const onClick = map((x_12) => x_12, FSharpMap__TryFind(p, "onClick"));
    const inverted = map((x_13) => x_13, FSharpMap__TryFind(p, "inverted"));
    props = {
        disabled: map((x_14) => x_14, FSharpMap__TryFind(p, "disabled")),
        id: id,
        inverted: inverted,
        label: label,
        left: left,
        leftIcon: leftIcon,
        loading: loading,
        loadingLabel: loadingLabel,
        onClick: onClick,
        right: right,
        rightIcon: rightIcon,
        size: unwrap(size),
        success: success,
        successLabel: successLabel,
        variant: unwrap(variant),
    };
    return react.createElement(FlatButton, props);
}

