import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { lambda_type, class_type, unit_type, record_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class Response_UserProfile extends Record {
    constructor(Name, Email) {
        super();
        this.Name = Name;
        this.Email = Email;
    }
}

export function Response_UserProfile_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Profiles.API.Response.UserProfile", [], Response_UserProfile, () => [["Name", string_type], ["Email", string_type]]);
}

export class ProfilesAPI extends Record {
    constructor(GetProfile) {
        super();
        this.GetProfile = GetProfile;
    }
}

export function ProfilesAPI_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Profiles.API.ProfilesAPI", [], ProfilesAPI, () => [["GetProfile", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Response_UserProfile_$reflection()]))]]);
}

export function ProfilesAPI_RouteBuilder(_arg, m) {
    return `/api/profiles/${m}`;
}

