import { Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class ToastPosition extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["TopLeft", "TopCenter", "TopRight", "BottomLeft", "BottomCenter", "BottomRight"];
    }
}

export function ToastPosition_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Components.HotToast.ToastPosition", [], ToastPosition, () => [[], [], [], [], [], []]);
}

export function ToastPositionModule_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "top-center";
        case 2:
            return "top-right";
        case 3:
            return "bottom-left";
        case 4:
            return "bottom-center";
        case 5:
            return "bottom-right";
        default:
            return "top-left";
    }
}

