import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { string_type, union_type, class_type, record_type, float64_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { maxBy, isEmpty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { op_Subtraction } from "../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { compare } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { compare as compare_1 } from "../fable_modules/fable-library-js.4.19.3/BigInt.js";
import { ticks } from "../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { printf, toFail } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { WeatherTypeModule_ParameterModule_fromWeatherType, WeatherType_$reflection } from "./Weather.js";

export class Point extends Record {
    constructor(Longitude, Latitude) {
        super();
        this.Longitude = Longitude;
        this.Latitude = Latitude;
    }
}

export function Point_$reflection() {
    return record_type("StenaWeather.Domain.Forecast.Point", [], Point, () => [["Longitude", float64_type], ["Latitude", float64_type]]);
}

export class TimePoint extends Record {
    constructor(Point, DateTime) {
        super();
        this.Point = Point;
        this.DateTime = DateTime;
    }
}

export function TimePoint_$reflection() {
    return record_type("StenaWeather.Domain.Forecast.TimePoint", [], TimePoint, () => [["Point", Point_$reflection()], ["DateTime", class_type("System.DateTimeOffset")]]);
}

export class SpatialDistance extends Record {
    constructor(LonDist, LatDist) {
        super();
        this.LonDist = LonDist;
        this.LatDist = LatDist;
    }
}

export function SpatialDistance_$reflection() {
    return record_type("StenaWeather.Domain.Forecast.SpatialDistance", [], SpatialDistance, () => [["LonDist", float64_type], ["LatDist", float64_type]]);
}

function sqr(x) {
    return x * x;
}

export const SpatialDistanceModule_zero = new SpatialDistance(0, 0);

export function SpatialDistanceModule_get(p1, p2) {
    return new SpatialDistance(p1.Longitude - p2.Longitude, p1.Latitude - p2.Latitude);
}

export function SpatialDistanceModule_inKilometers(p1, p2) {
    const p = 3.141592653589793 / 180;
    const a = (0.5 - (Math.cos((p2.Latitude - p1.Latitude) * p) / 2)) + (((Math.cos(p1.Latitude * p) * Math.cos(p2.Latitude * p)) * (1 - Math.cos((p2.Longitude - p1.Longitude) * p))) / 2);
    return 12742 * Math.asin(Math.sqrt(a));
}

export function SpatialDistanceModule_abs(sd) {
    return new SpatialDistance(Math.abs(sd.LonDist), Math.abs(sd.LatDist));
}

export function SpatialDistanceModule_angleDistance(lon, lat) {
    return Math.sqrt(sqr(lat) + sqr(lon));
}

export function SpatialDistanceModule_toAngleDist(sd) {
    return SpatialDistanceModule_angleDistance(sd.LonDist, sd.LatDist);
}

export function SpatialDistanceModule_mult(x, sd) {
    return new SpatialDistance(sd.LonDist * x, sd.LatDist * x);
}

export function PointModule_createLonLat(lon, lat) {
    return new Point(lon, lat);
}

export function PointModule_distance(p1, p2) {
    return Math.sqrt(sqr(p2.Latitude - p1.Latitude) + sqr(p2.Longitude - p1.Longitude));
}

export function TimePointModule_time(tp) {
    return tp.DateTime;
}

export function TimePointModule_point(tp) {
    return tp.Point;
}

export function TimePointModule_create(lon, lat, time) {
    return new TimePoint(new Point(lon, lat), time);
}

export function TimePointModule_getTTL(compareDate, tps) {
    if (isEmpty(tps)) {
        return 0;
    }
    else {
        const ts = op_Subtraction(TimePointModule_time(maxBy((x) => x.DateTime, tps, {
            Compare: compare,
        })), compareDate);
        if (compare_1(ticks(ts), 0n) < 0) {
            return 0;
        }
        else {
            return ts;
        }
    }
}

export class Provider extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DMI", "UkMetO", "FMI", "MetNo", "SMHI", "FCOO", "MetIr"];
    }
}

export function Provider_$reflection() {
    return union_type("StenaWeather.Domain.Forecast.Provider", [], Provider, () => [[], [], [], [], [], [], []]);
}

export function ProviderModule_name(_arg) {
    switch (_arg.tag) {
        case 1:
            return "ukmeto";
        case 2:
            return "fmi";
        case 3:
            return "metno";
        case 4:
            return "smhi";
        case 5:
            return "fcoo";
        case 6:
            return "metir";
        default:
            return "dmi";
    }
}

export function ProviderModule_create(s) {
    const matchValue = s.toLowerCase();
    switch (matchValue) {
        case "dmi":
            return new Provider(0, []);
        case "ukmeto":
            return new Provider(1, []);
        case "fmi":
            return new Provider(2, []);
        case "metno":
            return new Provider(3, []);
        case "smhi":
            return new Provider(4, []);
        case "fcoo":
            return new Provider(5, []);
        case "metir":
            return new Provider(6, []);
        default:
            return toFail(printf("Cannot parse provider name from string \"%s\""))(s);
    }
}

export class ModelInfo extends Record {
    constructor(Name, Created) {
        super();
        this.Name = Name;
        this.Created = Created;
    }
}

export function ModelInfo_$reflection() {
    return record_type("StenaWeather.Domain.Forecast.ModelInfo", [], ModelInfo, () => [["Name", string_type], ["Created", class_type("System.DateTimeOffset")]]);
}

export class PointForecast extends Record {
    constructor(TimePoint, Weather) {
        super();
        this.TimePoint = TimePoint;
        this.Weather = Weather;
    }
}

export function PointForecast_$reflection() {
    return record_type("StenaWeather.Domain.Forecast.PointForecast", [], PointForecast, () => [["TimePoint", TimePoint_$reflection()], ["Weather", WeatherType_$reflection()]]);
}

export function PointForecastModule_getParam(f) {
    return WeatherTypeModule_ParameterModule_fromWeatherType(f.Weather);
}

export class ForecastSource extends Record {
    constructor(Provider, Model) {
        super();
        this.Provider = Provider;
        this.Model = Model;
    }
}

export function ForecastSource_$reflection() {
    return record_type("StenaWeather.Domain.Forecast.ForecastSource", [], ForecastSource, () => [["Provider", Provider_$reflection()], ["Model", ModelInfo_$reflection()]]);
}

export class Forecast extends Record {
    constructor(PointForecast, Source) {
        super();
        this.PointForecast = PointForecast;
        this.Source = Source;
    }
}

export function Forecast_$reflection() {
    return record_type("StenaWeather.Domain.Forecast.Forecast", [], Forecast, () => [["PointForecast", PointForecast_$reflection()], ["Source", ForecastSource_$reflection()]]);
}

export function ForecastModule_getParam(f) {
    return PointForecastModule_getParam(f.PointForecast);
}

