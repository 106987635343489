import React from "react";
import * as react from "react";
import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Column } from "@stenajs-webui/core";

export function ColumnModule_View(columnModule_ViewInputProps) {
    const p = columnModule_ViewInputProps.p;
    let props;
    const width = map((x) => x, FSharpMap__TryFind(p, "width"));
    const height = map((x_1) => x_1, FSharpMap__TryFind(p, "height"));
    const alignItems = map((x_2) => x_2, FSharpMap__TryFind(p, "alignItems"));
    const justifyContent = map((x_3) => x_3, FSharpMap__TryFind(p, "justifyContent"));
    props = {
        alignItems: alignItems,
        gap: map((x_4) => x_4, FSharpMap__TryFind(p, "gap")),
        height: height,
        justifyContent: justifyContent,
        width: width,
    };
    const children = defaultArg(map((x_5) => x_5, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Column, props, ...children);
}

