import React from "react";
import * as react from "react";
import { map, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Crumb as Crumb_1, BreadCrumbs } from "@stenajs-webui/elements";
import ReactRouterCrumbComponent from "../../../../src/StenaWeather.WeatherServiceUI.Client/Components/StenaWebUI/Elements/ReactRouterCrumbComponent";
import { class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";

export function BreadCrumbsModule_View(breadCrumbsModule_ViewInputProps) {
    const p = breadCrumbsModule_ViewInputProps.p;
    const props = {};
    const children = defaultArg(map((x) => x, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(BreadCrumbs, props, ...children);
}

export function CrumbModule_View(crumbModule_ViewInputProps) {
    const p = crumbModule_ViewInputProps.p;
    const props = {
        label: map((x) => x, FSharpMap__TryFind(p, "label")),
        render: map((x_1) => x_1, FSharpMap__TryFind(p, "render")),
    };
    return react.createElement(Crumb_1, props);
}

export function ReactRouterCrumb_View(reactRouterCrumb_ViewInputProps) {
    const p = reactRouterCrumb_ViewInputProps.p;
    const l = map((x) => x, FSharpMap__TryFind(p, "label"));
    const d = map((x_1) => x_1, FSharpMap__TryFind(p, "dest"));
    let props;
    const label = map((x_2) => x_2, FSharpMap__TryFind(p, "label"));
    props = {
        dest: map((x_3) => x_3, FSharpMap__TryFind(p, "dest")),
        label: label,
    };
    return react.createElement(ReactRouterCrumbComponent, props);
}

export class Crumb {
    constructor() {
    }
}

export function Crumb_$reflection() {
    return class_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Elements.Crumb", undefined, Crumb);
}

