import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { lambda_type, unit_type, record_type, bool_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { createContext } from "../../fable_modules/Feliz.2.8.0/Internal.fs.js";
import React from "react";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";

export class DateTimeSettings extends Record {
    constructor(TimeZoneOffset, UseLocalTime) {
        super();
        this.TimeZoneOffset = TimeZoneOffset;
        this.UseLocalTime = UseLocalTime;
    }
}

export function DateTimeSettings_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Components.TimeZones.Context.DateTimeSettings", [], DateTimeSettings, () => [["TimeZoneOffset", class_type("System.TimeSpan")], ["UseLocalTime", bool_type]]);
}

export class TimezoneContext extends Record {
    constructor(Settings, SetSettings) {
        super();
        this.Settings = Settings;
        this.SetSettings = SetSettings;
    }
}

export function TimezoneContext_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Components.TimeZones.Context.TimezoneContext", [], TimezoneContext, () => [["Settings", DateTimeSettings_$reflection()], ["SetSettings", lambda_type(DateTimeSettings_$reflection(), unit_type)]]);
}

export const dateTimeSettings = new DateTimeSettings(0, false);

export const defaultContext = new TimezoneContext(dateTimeSettings, (_arg) => {
});

export const timeZoneContext = createContext("TimeZoneContext", defaultContext);

export function TimeZoneContext(timeZoneContextInputProps) {
    const children = timeZoneContextInputProps.children;
    const ctx = timeZoneContextInputProps.ctx;
    return reactApi.createElement(timeZoneContext.Provider, {
        value: ctx,
    }, children);
}

