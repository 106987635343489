import React from "react";
import * as react from "react";
import { map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { LoadingScreen } from "@stenajs-webui/panels";

export function LoadingScreenModule_View(loadingScreenModule_ViewInputProps) {
    const p = loadingScreenModule_ViewInputProps.p;
    const props = {
        text: map((x) => x, FSharpMap__TryFind(p, "text")),
    };
    return react.createElement(LoadingScreen, props);
}

