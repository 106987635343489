import React from "react";
import * as react from "react";
import { map } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { Point_toPosition } from "./Shared.js";
import { empty, toArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { map as map_1, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { Polyline } from "react-leaflet";

export function Polyline_View(polyline_ViewInputProps) {
    let color, dashArray, weight;
    const p = polyline_ViewInputProps.p;
    let props;
    const positions = map(Point_toPosition, toArray(defaultArg(map_1((x) => x, FSharpMap__TryFind(p, "points")), empty())));
    props = {
        pathOptions: (color = map_1((x_1) => x_1, FSharpMap__TryFind(p, "color")), (dashArray = map_1((x_2) => x_2, FSharpMap__TryFind(p, "dashArray")), (weight = (defaultArg(map_1((x_3) => x_3, FSharpMap__TryFind(p, "weight")), 3) | 0), {
            color: color,
            dashArray: dashArray,
            fillOpacity: defaultArg(map_1((x_4) => x_4, FSharpMap__TryFind(p, "fillOpacity")), 0.25),
            weight: weight,
        }))),
        positions: positions,
    };
    return react.createElement(Polyline, props);
}

