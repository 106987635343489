import React from "react";
import * as react from "react";
import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Label } from "@stenajs-webui/elements";

export function LabelModule_View(labelModule_ViewInputProps) {
    const p = labelModule_ViewInputProps.p;
    let props;
    const text = map((x) => x, FSharpMap__TryFind(p, "text"));
    const background = map((x_1) => x_1, FSharpMap__TryFind(p, "background"));
    const optional = map((x_2) => x_2, FSharpMap__TryFind(p, "optional"));
    props = {
        background: background,
        maxCharacters: map((x_3) => x_3, FSharpMap__TryFind(p, "maxCharacters")),
        optional: optional,
        text: text,
        textWidth: map((x_4) => x_4, FSharpMap__TryFind(p, "textWidth")),
    };
    const children = defaultArg(map((x_5) => x_5, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Label, props, ...children);
}

