import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { SimpleJson_tryParse } from "../../fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { Convert_serialize, Convert_fromJson } from "../../fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { createTypeInfo } from "../../fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { DateTimeSettings, DateTimeSettings_$reflection } from "./Context.js";
import { defaultOf } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { now, offset } from "../../fable_modules/fable-library-js.4.19.3/DateOffset.js";

function tryParseTimespan(timeSpanString) {
    let matchValue;
    let input_3;
    try {
        input_3 = (new FSharpResult$2(0, [(matchValue = SimpleJson_tryParse(timeSpanString), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(DateTimeSettings_$reflection())) : (() => {
            throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
        })())]));
    }
    catch (ex) {
        input_3 = (new FSharpResult$2(1, [ex.message]));
    }
    if (input_3.tag === 1) {
        return new FSharpResult$2(1, ["Invalid timespan"]);
    }
    else {
        return new FSharpResult$2(0, [input_3.fields[0]]);
    }
}

export function tryGetFromStorage() {
    let input_3;
    let input_1;
    const value_1 = localStorage.getItem("weatherservice.userTimezoneSettings.v2");
    input_1 = ((value_1 === defaultOf()) ? (new FSharpResult$2(1, ["No user timezone settings found in storage"])) : (new FSharpResult$2(0, [value_1])));
    input_3 = ((input_1.tag === 1) ? (new FSharpResult$2(1, [input_1.fields[0]])) : tryParseTimespan(input_1.fields[0]));
    if (input_3.tag === 1) {
        return undefined;
    }
    else {
        return input_3.fields[0];
    }
}

export function saveToStorage(ctx) {
    localStorage.setItem("weatherservice.userTimezoneSettings.v2", Convert_serialize(ctx, createTypeInfo(DateTimeSettings_$reflection())));
}

export function getDefaultUserSettings() {
    return new DateTimeSettings(offset(now()), true);
}

