import { toString, Record } from "./fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, anonRecord_type, record_type, string_type } from "./fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ProxyRequestException__get_Response, ProxyRequestException__get_StatusCode, ProxyRequestException } from "./fable_modules/Fable.Remoting.Client.7.32.0/Types.fs.js";
import { ServerError_$reflection, ServerError } from "./StenaWeather.WeatherServiceUI.Shared/Errors.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { Convert_fromJson } from "./fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { Cmd_OfAsyncWith_either } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { awaitPromise, catchAsync, start as start_1 } from "./fable_modules/fable-library-js.4.19.3/Async.js";
import { singleton } from "./fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { printf, toText } from "./fable_modules/fable-library-js.4.19.3/String.js";
import { map, defaultArg, toArray, unwrap, some } from "./fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpResult$2 } from "./fable_modules/fable-library-js.4.19.3/Result.js";
import { iterate } from "./fable_modules/fable-library-js.4.19.3/Seq.js";
import { Oidc_UserManager, Oidc_Log } from "./fable_modules/Fable.OidcClient.1.0.2/Fable.OidcClient.fs.js";
import { Remoting_buildProxy_64DC51C } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder, RemotingModule_withAuthorizationHeader } from "./fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { ProfilesAPI_$reflection, ProfilesAPI_RouteBuilder } from "./StenaWeather.WeatherServiceUI.Shared/Profiles/API.js";
import { ModelsAPI_$reflection, ModelsAPI_RouteBuilder } from "./StenaWeather.WeatherServiceUI.Shared/Models/API.js";
import { VesselsAPI_$reflection, VesselsAPI_RouteBuilder } from "./StenaWeather.WeatherServiceUI.Shared/Vessels/API.js";
import { SessionDetailsAPI_$reflection, SessionDetailsAPI_RouteBuilder } from "./StenaWeather.WeatherServiceUI.Shared/SessionDetails/API.js";
import { DashboardsAPI_$reflection, DashboardsAPI_RouteBuilder } from "./StenaWeather.WeatherServiceUI.Shared/Dashboards/API.js";
import { PortWindAPI_$reflection, PortWindAPI_RouteBuilder } from "./StenaWeather.WeatherServiceUI.Shared/PortWind/API.js";

export class Config extends Record {
    constructor(baseUrl, tenantId, clientId) {
        super();
        this.baseUrl = baseUrl;
        this.tenantId = tenantId;
        this.clientId = clientId;
    }
}

export function Config_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Server.Config", [], Config, () => [["baseUrl", string_type], ["tenantId", string_type], ["clientId", string_type]]);
}

export function exnToError(e) {
    let matchValue;
    if (e instanceof ProxyRequestException) {
        const ex = e;
        try {
            return ((matchValue = SimpleJson_tryParse(ProxyRequestException__get_Response(ex).ResponseBody), (matchValue != null) ? Convert_fromJson(matchValue, createTypeInfo(anonRecord_type(["error", ServerError_$reflection()]))) : (() => {
                throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
            })())).error;
        }
        catch (matchValue_1) {
            return (ProxyRequestException__get_StatusCode(ex) === 401) ? (new ServerError(2, [ProxyRequestException__get_Response(ex).ResponseBody])) : ((ProxyRequestException__get_StatusCode(ex) === 403) ? (new ServerError(3, [ProxyRequestException__get_Response(ex).ResponseBody])) : (new ServerError(0, [e.message])));
        }
    }
    else {
        return new ServerError(0, [e.message]);
    }
}

export function Cmd_OfAsync_eitherAsResult(fn, resultMsg) {
    return Cmd_OfAsyncWith_either((a) => {
        start_1(singleton.Delay(() => singleton.Bind(catchAsync(a), (_arg) => {
            if (_arg.tag === 1) {
                console.error(some(toText(printf("eitherAsResult: %A"))(_arg.fields[0])));
                return singleton.Zero();
            }
            else {
                _arg.fields[0];
                return singleton.Zero();
            }
        })));
    }, fn, undefined, (arg) => resultMsg(new FSharpResult$2(0, [arg])), (arg_2) => resultMsg(new FSharpResult$2(1, [exnToError(arg_2)])));
}

export const Auth_settings = (() => {
    const endpoint = `https://login.microsoftonline.com/${config.tenantId}/v2.0`;
    const client_id = config.clientId;
    const post_logout_redirect_uri = config.baseUrl;
    const redirect_uri = (config.baseUrl) + "login-callback";
    const silent_redirect_uri = (config.baseUrl) + "login-callback-silent";
    return {
        authority: endpoint,
        automaticSilentRenew: true,
        client_id: unwrap(client_id),
        filterProtocolClaims: true,
        loadUserInfo: false,
        post_logout_redirect_uri: unwrap(post_logout_redirect_uri),
        redirect_uri: unwrap(redirect_uri),
        response_type: "code",
        scope: `api://${config.clientId}/User.Read`,
        silent_redirect_uri: unwrap(silent_redirect_uri),
    };
})();

export class Auth_Log {
    constructor() {
    }
    debug(msg, ...pars) {
        iterate((x) => {
            console.debug(toString(x));
        }, toArray(msg));
    }
    error(msg, ...pars) {
        iterate((x) => {
            console.error(some(toString(x)));
        }, toArray(msg));
    }
    info(msg, ...pars) {
        iterate((x) => {
            console.info(some(toString(x)));
        }, toArray(msg));
    }
    warn(msg, ...pars) {
        iterate((x) => {
            console.warn(some(toString(x)));
        }, toArray(msg));
    }
}

export function Auth_Log_$reflection() {
    return class_type("StenaWeather.WeatherServiceUI.Client.Server.Auth.Log", undefined, Auth_Log);
}

export function Auth_Log_$ctor() {
    return new Auth_Log();
}

export const Auth_userManager = (Oidc_Log.logger = Auth_Log_$ctor(), (() => {
    const userManager = new Oidc_UserManager(Auth_settings);
    userManager.events.addAccessTokenExpiring((_arg) => {
        userManager.signinSilent(some({
            scope: unwrap(Auth_settings.scope),
        }));
    });
    return userManager;
})());

function withAuth(builder, fn) {
    return singleton.Delay(() => singleton.Bind(awaitPromise(Auth_userManager.getUser()), (_arg) => {
        const token = defaultArg(map((x) => x.access_token, _arg), "");
        return singleton.ReturnFrom(fn(builder(token)));
    }));
}

export function onProfilesAPI(fn) {
    return withAuth((token) => Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withRouteBuilder(ProfilesAPI_RouteBuilder, RemotingModule_createApi())), ProfilesAPI_$reflection()), fn);
}

export function onModelsAPI(fn) {
    return withAuth((token) => Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withRouteBuilder(ModelsAPI_RouteBuilder, RemotingModule_createApi())), ModelsAPI_$reflection()), fn);
}

export function onVesselsAPI(fn) {
    return withAuth((token) => Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withRouteBuilder(VesselsAPI_RouteBuilder, RemotingModule_createApi())), VesselsAPI_$reflection()), fn);
}

export function onSessionDetailsAPI(fn) {
    return withAuth((token) => Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withRouteBuilder(SessionDetailsAPI_RouteBuilder, RemotingModule_createApi())), SessionDetailsAPI_$reflection()), fn);
}

export function onDashboardsAPI(fn) {
    return withAuth((token) => Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withRouteBuilder(DashboardsAPI_RouteBuilder, RemotingModule_createApi())), DashboardsAPI_$reflection()), fn);
}

export function onPortWindAPI(fn) {
    return withAuth((token) => Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withRouteBuilder(PortWindAPI_RouteBuilder, RemotingModule_createApi())), PortWindAPI_$reflection()), fn);
}

