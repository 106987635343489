import React from "react";
import * as react from "react";
import { map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { Switch } from "@stenajs-webui/forms";

export function SwitchModule_View(switchModule_ViewInputProps) {
    const p = switchModule_ViewInputProps.p;
    let props;
    const value = map((x) => x, FSharpMap__TryFind(p, "value"));
    props = {
        onValueChange: map((x_1) => x_1, FSharpMap__TryFind(p, "onValueChange")),
        value: value,
    };
    return react.createElement(Switch, props);
}

