import * as react from "react";
import { StandardTable } from "@stenajs-webui/grid";
import { class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";

export function StandardTableModule_view(props) {
    return react.createElement(StandardTable, props);
}

export class standardTableVariant {
    constructor() {
    }
}

export function standardTableVariant_$reflection() {
    return class_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Grid.standardTableVariant", undefined, standardTableVariant);
}

