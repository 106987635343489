import { toast } from "react-hot-toast";
import { defaultArg, unwrap, map } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { ToastPositionModule_value } from "./HotToast.js";
import { empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import * as react from "react";

export const ToastModule__customToast = toast.custom;

export function ToastModule_propsToRecord(p) {
    return {
        duration: map((x) => x, FSharpMap__TryFind(p, "duration")),
        position: unwrap(map(ToastPositionModule_value, map((x_1) => x_1, FSharpMap__TryFind(p, "position")))),
    };
}

export function ToastModule_customToast(p) {
    let xs;
    ToastModule__customToast((xs = defaultArg(map((x) => x, FSharpMap__TryFind(p, "children")), empty()), react.createElement(react.Fragment, {}, ...xs)), ToastModule_propsToRecord(p));
}

