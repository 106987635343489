import { map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import * as react from "react";
import { Icon } from "@stenajs-webui/elements";

export function IconModule_View(p) {
    const props = {
        color: map((x) => x, FSharpMap__TryFind(p, "color")),
        icon: map((x_1) => x_1, FSharpMap__TryFind(p, "icon")),
        size: map((x_2) => x_2, FSharpMap__TryFind(p, "size")),
    };
    return react.createElement(Icon, props);
}

