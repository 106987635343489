import React from "react";
import * as react from "react";
import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Row } from "@stenajs-webui/core";

export function RowModule_View(rowModule_ViewInputProps) {
    const p = rowModule_ViewInputProps.p;
    let props;
    const justifyContent = map((x) => x, FSharpMap__TryFind(p, "justifyContent"));
    props = {
        flex: map((x_1) => x_1, FSharpMap__TryFind(p, "flex")),
        height: map((x_2) => x_2, FSharpMap__TryFind(p, "height")),
        justifyContent: justifyContent,
    };
    const children = defaultArg(map((x_3) => x_3, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Row, props, ...children);
}

