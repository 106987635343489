
export const msToKnotsFactor = 1.94384;

export function msToKnots(ms) {
    return (ms / 1) * (msToKnotsFactor * 1);
}

export function knotsToMs(knots) {
    return ((knots / 1) / msToKnotsFactor) * 1;
}

