export const faCustomCurrentIcon = {
    prefix: "custom",
    iconName: "current-icon",
    //transform: "translate(0.000000,50.000000) scale(0.100000,-0.100000)",
    icon: [
        400,
        295,
        [],
        "f0000",
        "M88.355 76.236 C 68.236 115.672,78.819 128.397,100.779 91.176 C 115.901 65.547,118.947 65.547,131.168 91.176 C 138.572 106.701,144.650 111.765,155.882 111.765 C 167.115 111.765,173.193 106.701,180.596 91.176 C 185.996 79.853,192.536 70.588,195.128 70.588 C 197.721 70.588,203.713 79.853,208.444 91.176 C 220.535 120.113,243.268 120.113,257.067 91.176 C 262.467 79.853,268.780 70.588,271.097 70.588 C 273.413 70.588,280.775 79.853,287.456 91.176 C 309.268 128.146,319.092 117.376,299.863 77.575 C 284.054 44.856,263.274 43.209,248.815 73.529 C 243.416 84.853,237.102 94.118,234.786 94.118 C 232.469 94.118,225.108 84.853,218.426 73.529 C 202.454 46.457,185.782 46.457,169.809 73.529 C 163.128 84.853,155.766 94.118,153.450 94.118 C 151.133 94.118,144.820 84.853,139.420 73.529 C 125.250 43.815,104.329 44.924,88.355 76.236 M255.983 146.896 C 253.676 150.629,256.019 158.356,261.188 164.069 C 277.044 181.589,273.506 182.353,176.471 182.353 C 117.647 182.353,82.353 184.559,82.353 188.235 C 82.353 191.912,117.647 194.118,176.471 194.118 C 273.993 194.118,277.106 194.812,260.727 212.911 C 239.753 236.088,262.029 234.327,287.162 210.821 C 301.131 197.758,311.678 186.670,310.601 186.182 C 309.524 185.693,297.738 175.128,284.410 162.702 C 268.316 147.699,258.768 142.390,255.983 146.896"
        ]
};