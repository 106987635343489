import { Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import React from "react";
import * as react from "react";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { empty, map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import server from "react-dom/server";
import { map as map_1, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import LegendControl from "../../../src/StenaWeather.WeatherServiceUI.Client/Components/Leaflet/LegendControl.js";

export class LegendPosition extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["TopLeft", "TopRight", "BottomLeft", "BottomRight"];
    }
}

export function LegendPosition_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Components.Leaflet.LegendPosition", [], LegendPosition, () => [[], [], [], []]);
}

export function LegendPositionModule_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "topright";
        case 2:
            return "bottomleft";
        case 3:
            return "bottomright";
        default:
            return "topleft";
    }
}

export function Legend_View(legend_ViewInputProps) {
    const p = legend_ViewInputProps.p;
    const props = {
        html: join("", map((element) => server.renderToString(element), defaultArg(map_1((x) => x, FSharpMap__TryFind(p, "children")), empty()))),
        position: LegendPositionModule_value(defaultArg(map_1((x_1) => x_1, FSharpMap__TryFind(p, "position")), new LegendPosition(3, []))),
    };
    return react.createElement(LegendControl, props);
}

