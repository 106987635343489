import Components$002Emodule from "../../../src/StenaWeather.WeatherServiceUI.Client/Components/TimeZones/Components.module.css";
import { singleton, ofArray, map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { interpolate, toText, join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { toString } from "../../fable_modules/fable-library-js.4.19.3/Date.js";
import { utcNow, now, offset, toOffset } from "../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { DateTimeSettings, timeZoneContext } from "./Context.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { fromMinutes, fromHours, minutes as minutes_2, totalHours } from "../../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { ofList } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { comparePrimitives, int32ToString } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.3/Int32.js";
import { NumericTextInputModule_View } from "../StenaWebUI/Forms/NumericTextInput.js";
import { ButtonBase_ViewFlat, ButtonBase_ViewPrimary, ButtonBase_ViewSecondary } from "../StenaWebUI/Elements/Button.js";
import { useEffectWithDeps } from "../../fable_modules/Feliz.2.8.0/ReactInterop.js";
import { createDisposable } from "../../fable_modules/Feliz.2.8.0/Internal.fs.js";
import { SwitchModule_View } from "../StenaWebUI/Forms/Switch.js";
import { ModalModule_view } from "../StenaWebUI/Modal/Modal.js";

export const cn = (() => {
    const styles = Components$002Emodule;
    const cnFn = (name) => (styles[name]);
    const cnFns = (names) => map((x) => (styles[x]), names);
    return {
        className: cnFn,
        classes: cnFns,
        prop: {
            className: (arg) => ["className", cnFn(arg)],
            classes: (arg_1) => ["className", join(" ", cnFns(arg_1))],
        },
    };
})();

export function DateTimeOffset_dateStr(d) {
    return toString(d, "yyyy-MM-dd");
}

export function DateTimeOffset_timeStr(d) {
    return toString(d, "HH:mm");
}

export function DateTimeOffset_dateTimeStr(d) {
    return (DateTimeOffset_dateStr(d) + " ") + DateTimeOffset_timeStr(d);
}

export function DateTimeOffset_adjustFromContext(ctx, dateTime) {
    if (ctx.Settings.UseLocalTime) {
        return toOffset(dateTime, ctx.Settings.TimeZoneOffset);
    }
    else {
        return toOffset(dateTime, 0);
    }
}

export function DateTimeOffset_dateTimeWithTimezone(d) {
    return DateTimeOffset_adjustFromContext(reactApi.useContext(timeZoneContext), d);
}

export function DateTimeOffset_DateWithTimezone(dateTimeOffset_DateWithTimezoneInputProps) {
    const dateTime = dateTimeOffset_DateWithTimezoneInputProps.dateTime;
    return DateTimeOffset_dateStr(DateTimeOffset_adjustFromContext(reactApi.useContext(timeZoneContext), dateTime));
}

export function DateTimeOffset_TimeWithTimezone(dateTimeOffset_TimeWithTimezoneInputProps) {
    const dateTime = dateTimeOffset_TimeWithTimezoneInputProps.dateTime;
    return DateTimeOffset_timeStr(DateTimeOffset_adjustFromContext(reactApi.useContext(timeZoneContext), dateTime));
}

export function DateTimeOffset_DateTimeWithTimezone(dateTimeOffset_DateTimeWithTimezoneInputProps) {
    const dateTime = dateTimeOffset_DateTimeWithTimezoneInputProps.dateTime;
    return DateTimeOffset_dateTimeStr(DateTimeOffset_adjustFromContext(reactApi.useContext(timeZoneContext), dateTime));
}

export function TimezoneSettingsForm(timezoneSettingsFormInputProps) {
    let elm_2, p_1_1, p_1_2, p_1_4;
    const closeModal = timezoneSettingsFormInputProps.closeModal;
    const ctx = reactApi.useContext(timeZoneContext);
    let patternInput;
    const initial = ~~totalHours(ctx.Settings.TimeZoneOffset) | 0;
    patternInput = reactApi.useState(initial);
    const setHoursState = patternInput[1];
    const hours = patternInput[0] | 0;
    let patternInput_1;
    const initial_1 = minutes_2(ctx.Settings.TimeZoneOffset) | 0;
    patternInput_1 = reactApi.useState(initial_1);
    const setMinutesState = patternInput_1[1];
    const minutes = patternInput_1[0] | 0;
    const elm = ofArray([(elm_2 = ofArray([(p_1_1 = ofList(map((value_15) => value_15, ofArray([["value", int32ToString(hours)], ["max", 14], ["min", -14], ["onValueChange", (arg) => {
        const hours_1 = parse(arg, 511, false, 32) | 0;
        setHoursState(hours_1);
        if (Math.abs(hours_1) >= 14) {
            setMinutesState(0);
        }
        if ((hours_1 !== 0) && (minutes < 0)) {
            setMinutesState(Math.abs(minutes));
        }
    }]])), {
        Compare: comparePrimitives,
    }), createElement(NumericTextInputModule_View, {
        p: p_1_1,
    })), ":", (p_1_2 = ofList(map((value_19) => value_19, ofArray([["value", int32ToString(minutes)], ["max", 59], ["min", (hours === 0) ? -59 : 0], ["onValueChange", (arg_1) => {
        const minutes_1 = parse(arg_1, 511, false, 32) | 0;
        if (Math.abs(hours) >= 14) {
            setMinutesState(0);
        }
        else {
            setMinutesState(minutes_1);
        }
    }]])), {
        Compare: comparePrimitives,
    }), createElement(NumericTextInputModule_View, {
        p: p_1_2,
    }))]), createElement("div", {
        className: cn.className("offset-settings"),
        children: reactApi.Children.toArray(Array.from(elm_2)),
    })), ButtonBase_ViewSecondary(ofList(map((value_20) => value_20, ofArray([["onClick", () => {
        const nowOffset = offset(now());
        setHoursState(~~totalHours(nowOffset));
        setMinutesState(minutes_2(nowOffset));
    }], ["label", "Set local offset"]])), {
        Compare: comparePrimitives,
    })), (p_1_4 = ofList(map((value_21) => value_21, ofArray([["label", "Save"], ["onClick", () => {
        ctx.SetSettings(new DateTimeSettings((hours < 0) ? (fromHours(hours) - fromMinutes(minutes)) : (fromHours(hours) + fromMinutes(minutes)), ctx.Settings.UseLocalTime));
        return closeModal();
    }]])), {
        Compare: comparePrimitives,
    }), createElement(ButtonBase_ViewPrimary, {
        p: p_1_4,
    }))]);
    return createElement("div", {
        className: cn.className("timezone-settings-form"),
        children: reactApi.Children.toArray(Array.from(elm)),
    });
}

export function CurrentSettingsDisplay() {
    let value, value_2, p_1_1, elm;
    const ctx = reactApi.useContext(timeZoneContext);
    let patternInput;
    const initial = utcNow();
    patternInput = reactApi.useState(initial);
    const currentTime = patternInput[0];
    const patternInput_1 = reactApi.useState(false);
    const setModalVisible = patternInput_1[1];
    const modalVisible = patternInput_1[0];
    useEffectWithDeps(() => {
        const interval = window.setInterval(() => {
            patternInput[1](utcNow());
        }, 500);
        return createDisposable(() => {
            window.clearInterval(interval);
        });
    }, []);
    const hoursOffset = toText(interpolate("%02d%P()", [~~((value = totalHours(ctx.Settings.TimeZoneOffset), Math.abs(value)))]));
    const minutesOffset = toText(interpolate("%02d%P()", [(value_2 = (minutes_2(ctx.Settings.TimeZoneOffset) | 0), Math.abs(value_2))]));
    const displayValue = (ctx.Settings.TimeZoneOffset >= 0) ? (`UTC +${hoursOffset}:${minutesOffset}`) : (`UTC -${hoursOffset}:${minutesOffset}`);
    let switch$;
    const xs_2 = [createElement("span", {
        children: "UTC",
    }), (p_1_1 = ofList(map((value_6) => value_6, ofArray([["value", ctx.Settings.UseLocalTime], ["onValueChange", (_arg) => {
        ctx.SetSettings(new DateTimeSettings(ctx.Settings.TimeZoneOffset, !ctx.Settings.UseLocalTime));
    }]])), {
        Compare: comparePrimitives,
    }), createElement(SwitchModule_View, {
        p: p_1_1,
    })), createElement("span", {
        children: "Local",
    })];
    switch$ = react.createElement(react.Fragment, {}, ...xs_2);
    const toggleModal = () => {
        setModalVisible(!modalVisible);
    };
    const settingsButton = ButtonBase_ViewFlat(ofList(map((value_10) => value_10, ofArray([["label", "Settings"], ["onClick", toggleModal], ["inverted", true]])), {
        Compare: comparePrimitives,
    }));
    const xs_10 = [(elm = ofArray([createElement("div", {
        className: cn.className("time-display"),
        children: createElement(DateTimeOffset_TimeWithTimezone, {
            dateTime: currentTime,
        }),
    }), createElement("div", {
        className: cn.className("date-display"),
        children: createElement(DateTimeOffset_DateWithTimezone, {
            dateTime: currentTime,
        }),
    }), createElement("div", {
        className: cn.className("offset-display"),
        children: displayValue,
    }), createElement("div", {
        className: cn.className("offset-switch"),
        children: switch$,
    }), createElement("div", {
        className: cn.className("offset-setting-button"),
        children: settingsButton,
    })]), createElement("div", {
        className: cn.className("timezone-display"),
        children: reactApi.Children.toArray(Array.from(elm)),
    })), ModalModule_view(ofList(map((value_35) => value_35, ofArray([["isOpen", modalVisible], ["headerText", "Timezone Settings"], ["onRequestClose", toggleModal], ["width", "300px"], ["children", singleton(createElement(TimezoneSettingsForm, {
        closeModal: () => {
            setModalVisible(false);
        },
    }))]])), {
        Compare: comparePrimitives,
    }))];
    return react.createElement(react.Fragment, {}, ...xs_10);
}

