import React from "react";
import * as react from "react";
import { defaultArg, map, defaultArgWith } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { map as map_1 } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { Point_toPosition } from "./Shared.js";
import { empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Rectangle } from "react-leaflet";

export function Rectangle_View(rectangle_ViewInputProps) {
    let color, weight;
    const p = rectangle_ViewInputProps.p;
    const bounds = defaultArgWith(map((x) => x, FSharpMap__TryFind(p, "bounds")), () => {
        throw new Error("Bounds needs to be setup for Rectangle");
    });
    const props = {
        bounds: map_1(Point_toPosition, [bounds[0], bounds[1]]),
        pathOptions: (color = map((x_1) => x_1, FSharpMap__TryFind(p, "color")), (weight = (defaultArg(map((x_2) => x_2, FSharpMap__TryFind(p, "weight")), 3) | 0), {
            color: color,
            fillOpacity: defaultArg(map((x_3) => x_3, FSharpMap__TryFind(p, "fillOpacity")), 0.25),
            weight: weight,
        })),
    };
    const children = defaultArg(map((x_4) => x_4, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Rectangle, props, ...children);
}

