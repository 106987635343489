import { Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { defaultArg, unwrap, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import * as react from "react";
import { Text as Text$ } from "@stenajs-webui/core";

export class TextVariant extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Standard", "Caption", "Overline", "Bold"];
    }
}

export function TextVariant_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Core.TextVariant", [], TextVariant, () => [[], [], [], []]);
}

export function TextVariantModule_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "caption";
        case 2:
            return "overline";
        case 3:
            return "bold";
        default:
            return "standard";
    }
}

export class TextSize extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Large", "Medium", "Small", "Smaller"];
    }
}

export function TextSize_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Core.TextSize", [], TextSize, () => [[], [], [], []]);
}

export function TextSizeModule_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "medium";
        case 2:
            return "small";
        case 3:
            return "smaller";
        default:
            return "large";
    }
}

export class UserSelectType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MozInitial", "Inherit", "Initial", "Revert", "Unset", "Auto", "Text", "NoneValue", "MozNone", "All", "Contain", "Element"];
    }
}

export function UserSelectType_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Core.UserSelectType", [], UserSelectType, () => [[], [], [], [], [], [], [], [], [], [], [], []]);
}

export function UserSelectTypeModule_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "inherit";
        case 2:
            return "initial";
        case 3:
            return "revert";
        case 4:
            return "unset";
        case 5:
            return "auto";
        case 6:
            return "text";
        case 7:
            return "none";
        case 8:
            return "-moz-none";
        case 9:
            return "all";
        case 10:
            return "contain";
        case 11:
            return "element";
        default:
            return "-moz-initial";
    }
}

export class WhiteSpaceType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MozInitial", "Inherit", "Initial", "Revert", "Unset", "Pre", "Normal", "BreakSpaces", "MozPreWrap", "NoWrap", "PreLine", "PreWrap"];
    }
}

export function WhiteSpaceType_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Core.WhiteSpaceType", [], WhiteSpaceType, () => [[], [], [], [], [], [], [], [], [], [], [], []]);
}

export function WhiteSpaceTypeModule_value(_arg) {
    switch (_arg.tag) {
        case 1:
            return "inherit";
        case 2:
            return "initial";
        case 3:
            return "revert";
        case 4:
            return "unset";
        case 5:
            return "pre";
        case 7:
            return "break-spaces";
        case 6:
            return "normal";
        case 8:
            return "-moz-pre-wrap";
        case 9:
            return "nowrap";
        case 10:
            return "pre-line";
        case 11:
            return "pre-wrap";
        default:
            return "-moz-initial";
    }
}

export function TextModule_View(p) {
    let props;
    const color = map((x) => x, FSharpMap__TryFind(p, "color"));
    const variant = map(TextVariantModule_value, map((x_1) => x_1, FSharpMap__TryFind(p, "variant")));
    const userSelect = map(UserSelectTypeModule_value, map((x_2) => x_2, FSharpMap__TryFind(p, "userSelect")));
    const whiteSpace = map(WhiteSpaceTypeModule_value, map((x_3) => x_3, FSharpMap__TryFind(p, "whiteSpace")));
    props = {
        color: color,
        size: unwrap(map(TextSizeModule_value, map((x_4) => x_4, FSharpMap__TryFind(p, "size")))),
        userSelect: unwrap(userSelect),
        variant: unwrap(variant),
        whiteSpace: unwrap(whiteSpace),
    };
    const children = defaultArg(map((x_5) => x_5, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Text$, props, ...children);
}

