import React from "react";
import * as react from "react";
import { map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { Chip } from "@stenajs-webui/elements";

export function ChipModule_View(chipModule_ViewInputProps) {
    const p = chipModule_ViewInputProps.p;
    let props;
    const className = map((x) => x, FSharpMap__TryFind(p, "className"));
    const onClick = map((x_1) => x_1, FSharpMap__TryFind(p, "onClick"));
    const onClickRemove = map((x_2) => x_2, FSharpMap__TryFind(p, "onClickRemove"));
    props = {
        className: className,
        label: map((x_3) => x_3, FSharpMap__TryFind(p, "label")),
        onClick: onClick,
        onClickRemove: onClickRemove,
        variant: map((x_4) => x_4, FSharpMap__TryFind(p, "variant")),
    };
    return react.createElement(Chip, props);
}

