import React from "react";
import * as react from "react";
import { unwrap, defaultArg, map } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Point_toPosition } from "./Shared.js";
import { FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Popup } from "react-leaflet";

export function Popup_View(popup_ViewInputProps) {
    const p = popup_ViewInputProps.p;
    let props;
    const position = map(Point_toPosition, map((x) => x, FSharpMap__TryFind(p, "position")));
    const onClose = map((x_1) => x_1, FSharpMap__TryFind(p, "onClose"));
    props = {
        closeButton: defaultArg(map((x_2) => x_2, FSharpMap__TryFind(p, "closeButton")), true),
        closeOnClick: defaultArg(map((x_3) => x_3, FSharpMap__TryFind(p, "closeOnClick")), true),
        onClose: onClose,
        position: unwrap(position),
    };
    const children = defaultArg(map((x_4) => x_4, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Popup, props, ...children);
}

