import { class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import * as react from "react";
import { Tag } from "@stenajs-webui/elements";

export class TagVariant {
    constructor() {
    }
}

export function TagVariant_$reflection() {
    return class_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Elements.TagVariant", undefined, TagVariant);
}

export class TagSize {
    constructor() {
    }
}

export function TagSize_$reflection() {
    return class_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Elements.TagSize", undefined, TagSize);
}

export function TagModule_View(p) {
    let props;
    const label = map((x) => x, FSharpMap__TryFind(p, "label"));
    const variant = map((x_1) => x_1, FSharpMap__TryFind(p, "variant"));
    props = {
        label: label,
        size: map((x_2) => x_2, FSharpMap__TryFind(p, "size")),
        variant: variant,
    };
    const children = defaultArg(map((x_3) => x_3, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Tag, props, ...children);
}

