import * as React from "react";
import { Crumb } from "@stenajs-webui/elements";

class ReactRouterCrumbComponent extends React.Component {
    render() {
        return (
            <a href={this.props.dest}><Crumb label={this.props.label} /></a>
        );
    }
}

export default ReactRouterCrumbComponent;