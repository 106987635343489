import { Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, record_type, bool_type, float64_type, list_type, option_type, union_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Point_$reflection } from "./Forecast.js";
import { empty } from "../fable_modules/fable-library-js.4.19.3/List.js";

export class SensorId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["SensorId"];
    }
}

export function SensorId_$reflection() {
    return union_type("StenaWeather.Domain.PortWind.SensorId", [], SensorId, () => [[["Item", string_type]]]);
}

export function SensorId__get_Value(x) {
    return x.fields[0];
}

export class SensorMetaData extends Record {
    constructor(SensorId, Name, Imei, Location, Address, Urls, QuayOrientation, QuayFlipVessel, Visible, Enabled) {
        super();
        this.SensorId = SensorId;
        this.Name = Name;
        this.Imei = Imei;
        this.Location = Location;
        this.Address = Address;
        this.Urls = Urls;
        this.QuayOrientation = QuayOrientation;
        this.QuayFlipVessel = QuayFlipVessel;
        this.Visible = Visible;
        this.Enabled = Enabled;
    }
}

export function SensorMetaData_$reflection() {
    return record_type("StenaWeather.Domain.PortWind.SensorMetaData", [], SensorMetaData, () => [["SensorId", SensorId_$reflection()], ["Name", string_type], ["Imei", option_type(string_type)], ["Location", option_type(Point_$reflection())], ["Address", option_type(string_type)], ["Urls", list_type(string_type)], ["QuayOrientation", option_type(float64_type)], ["QuayFlipVessel", bool_type], ["Visible", bool_type], ["Enabled", bool_type]]);
}

export function SensorMetaData_Default(sensorId, name) {
    return new SensorMetaData(sensorId, name, undefined, undefined, undefined, empty(), undefined, false, true, true);
}

export class PortWindWeather extends Record {
    constructor(DateTime, WindSpeed, WindSpeedAverage, WindDirection, GustWindSpeed, MaxGustWindSpeed, WaveHeight, TideHeight, TidalFlowSpeed) {
        super();
        this.DateTime = DateTime;
        this.WindSpeed = WindSpeed;
        this.WindSpeedAverage = WindSpeedAverage;
        this.WindDirection = WindDirection;
        this.GustWindSpeed = GustWindSpeed;
        this.MaxGustWindSpeed = MaxGustWindSpeed;
        this.WaveHeight = WaveHeight;
        this.TideHeight = TideHeight;
        this.TidalFlowSpeed = TidalFlowSpeed;
    }
}

export function PortWindWeather_$reflection() {
    return record_type("StenaWeather.Domain.PortWind.PortWindWeather", [], PortWindWeather, () => [["DateTime", class_type("System.DateTimeOffset")], ["WindSpeed", option_type(float64_type)], ["WindSpeedAverage", option_type(float64_type)], ["WindDirection", option_type(float64_type)], ["GustWindSpeed", option_type(float64_type)], ["MaxGustWindSpeed", option_type(float64_type)], ["WaveHeight", option_type(float64_type)], ["TideHeight", option_type(float64_type)], ["TidalFlowSpeed", option_type(float64_type)]]);
}

export function PortWindWeather_Default_Z53C0511E(dt) {
    return new PortWindWeather(dt, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

export class PortWindWeatherResult extends Record {
    constructor(Weather, LastUpdated, Location) {
        super();
        this.Weather = Weather;
        this.LastUpdated = LastUpdated;
        this.Location = Location;
    }
}

export function PortWindWeatherResult_$reflection() {
    return record_type("StenaWeather.Domain.PortWind.PortWindWeatherResult", [], PortWindWeatherResult, () => [["Weather", list_type(PortWindWeather_$reflection())], ["LastUpdated", class_type("System.DateTimeOffset")], ["Location", option_type(Point_$reflection())]]);
}

