import { Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import React from "react";
import * as react from "react";
import { map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { CheckboxWithLabel } from "@stenajs-webui/forms";

export class CheckboxSize extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Standard", "Small"];
    }
}

export function CheckboxSize_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Elements.CheckboxSize", [], CheckboxSize, () => [[], []]);
}

export function CheckboxSizeModule_value(_arg) {
    if (_arg.tag === 1) {
        return "small";
    }
    else {
        return "standard";
    }
}

export function CheckboxWithLabelModule_View(checkboxWithLabelModule_ViewInputProps) {
    const p = checkboxWithLabelModule_ViewInputProps.p;
    let props;
    const label = map((x) => x, FSharpMap__TryFind(p, "label"));
    const disabled = map((x_1) => x_1, FSharpMap__TryFind(p, "disabled"));
    const indeterminate = map((x_2) => x_2, FSharpMap__TryFind(p, "indeterminate"));
    const size = map((x_3) => x_3, FSharpMap__TryFind(p, "size"));
    const value = map((x_4) => x_4, FSharpMap__TryFind(p, "value"));
    props = {
        disabled: disabled,
        indeterminate: indeterminate,
        label: label,
        onChange: map((x_5) => x_5, FSharpMap__TryFind(p, "onChange")),
        onValueChange: map((x_6) => x_6, FSharpMap__TryFind(p, "onValueChange")),
        size: size,
        value: value,
    };
    return react.createElement(CheckboxWithLabel, props);
}

