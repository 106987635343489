import { Cmd_batch, Cmd_none } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { singleton } from "./fable_modules/fable-library-js.4.19.3/List.js";

export function noCmd(v) {
    return [v, Cmd_none()];
}

export function withCmd(cmd, v) {
    return [v, cmd];
}

export function withCmds(cmds, v) {
    return [v, Cmd_batch(cmds)];
}

export function withCmdOfMsg(msg, v) {
    return [v, singleton((dispatch) => {
        dispatch(msg);
    })];
}

