import React from "react";
import * as react from "react";
import { map, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { Point } from "../../StenaWeather.Domain/Forecast.js";
import MapEventsControl from "../../../src/StenaWeather.WeatherServiceUI.Client/Components/Leaflet/MapEventsControl.js";

export function MapEvents_View(mapEvents_ViewInputProps) {
    const p = mapEvents_ViewInputProps.p;
    const onClick = defaultArg(map((x) => x, FSharpMap__TryFind(p, "onClick")), (value) => {
    });
    const props = {
        onClick: (lat, lon) => {
            onClick(new Point(lon, lat));
        },
    };
    return react.createElement(MapEventsControl, props);
}

