import { format } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { DateTimeOffset_dateTimeStr, DateTimeOffset_timeStr, cn as cn_2, DateTimeOffset_adjustFromContext } from "../Components/TimeZones/Components.js";
import { TextModule_View } from "../Components/StenaWebUI/Core/Text.js";
import { ofList } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { singleton, ofArray, map } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { WhiteSpaceType, UserSelectType, TextSize, TextVariant } from "../Components/StenaWebUI/Core/Text.js";
import { comparePrimitives } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { timeZoneContext } from "../Components/TimeZones/Context.js";
import { ChipModule_View } from "../Components/StenaWebUI/Elements/Chip.js";

export function round(x) {
    return format('{0:' + "0" + '}', x);
}

export function rotateDirection180(x) {
    return x + ((x < 180) ? 180 : -180);
}

export function timeInContext(ctx, strF, time) {
    return strF(DateTimeOffset_adjustFromContext(ctx, time));
}

export function DivsForStandardTable_defaultDiv(value) {
    const children = singleton(TextModule_View(ofList(map((value_2) => value_2, ofArray([["variant", new TextVariant(0, [])], ["size", new TextSize(1, [])], ["userSelect", new UserSelectType(6, [])], ["whiteSpace", new WhiteSpaceType(4, [])], ["children", value]])), {
        Compare: comparePrimitives,
    })));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

function DivsForStandardTable_timeChip(strF, time) {
    let p_1_1;
    const ctx = reactApi.useContext(timeZoneContext);
    return createElement("div", {
        className: cn_2.className("timeCell"),
        children: (p_1_1 = ofList(map((value_4) => value_4, ofArray([["label", timeInContext(ctx, strF, time)], ["variant", "secondary"]])), {
            Compare: comparePrimitives,
        }), createElement(ChipModule_View, {
            p: p_1_1,
        })),
    });
}

export function DivsForStandardTable_timeDiv(time) {
    return DivsForStandardTable_timeChip(DateTimeOffset_timeStr, time);
}

export function DivsForStandardTable_dateTimeDiv(time) {
    return DivsForStandardTable_timeChip(DateTimeOffset_dateTimeStr, time);
}

