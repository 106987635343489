import React from "react";
import * as react from "react";
import { map, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { PointModule_createLonLat } from "../../StenaWeather.Domain/Forecast.js";
import { FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { FeatureGroup } from "react-leaflet";

export function FeatureGroup_View(featureGroup_ViewInputProps) {
    const p = featureGroup_ViewInputProps.p;
    const props = {
        eventHandlers: {
            click: defaultArg(map((fn) => ((args) => {
                fn(PointModule_createLonLat(args.latlng.lng, args.latlng.lat));
            }), map((x) => x, FSharpMap__TryFind(p, "onClick"))), (value) => {
            }),
        },
    };
    const children = defaultArg(map((x_1) => x_1, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(FeatureGroup, props, ...children);
}

