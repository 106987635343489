import React from "react";
import * as react from "react";
import { unwrap, defaultArg, map } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Point_toPosition } from "./Shared.js";
import { FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { map as map_1 } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { empty, toArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { MapContainer } from "react-leaflet";

export function MapContainer_View(mapContainer_ViewInputProps) {
    const p = mapContainer_ViewInputProps.p;
    let props;
    const center = map(Point_toPosition, map((x) => x, FSharpMap__TryFind(p, "center")));
    const zoom = map((x_1) => x_1, FSharpMap__TryFind(p, "zoom"));
    const scrollWheelZoom = map((x_2) => x_2, FSharpMap__TryFind(p, "scrollWheelZoom"));
    props = {
        bounds: map_1(Point_toPosition, toArray(defaultArg(map((x_3) => x_3, FSharpMap__TryFind(p, "bounds")), empty()))),
        center: unwrap(center),
        maxBoundsViscosity: 1,
        scrollWheelZoom: scrollWheelZoom,
        zoom: zoom,
    };
    const children = defaultArg(map((x_4) => x_4, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(MapContainer, props, ...children);
}

