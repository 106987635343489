import React from "react";
import * as react from "react";
import { map } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { Point_toPosition } from "./Shared.js";
import { empty, toArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { map as map_1, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { Polygon } from "react-leaflet";

export function Polygon_View(polygon_ViewInputProps) {
    const p = polygon_ViewInputProps.p;
    let props;
    const positions = map(Point_toPosition, toArray(defaultArg(map_1((x) => x, FSharpMap__TryFind(p, "points")), empty())));
    props = {
        pathOptions: {
            color: map_1((x_1) => x_1, FSharpMap__TryFind(p, "color")),
            fill: map_1((x_2) => x_2, FSharpMap__TryFind(p, "fill")),
            fillOpacity: map_1((x_3) => x_3, FSharpMap__TryFind(p, "fillOpacity")),
        },
        positions: positions,
    };
    return react.createElement(Polygon, props);
}

