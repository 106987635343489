import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, lambda_type, class_type, list_type, unit_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { PortWindWeatherResult_$reflection, SensorId_$reflection, SensorMetaData_$reflection } from "../../StenaWeather.Domain/PortWind.js";

export class PortWindAPI extends Record {
    constructor(GetSensors, GetWeather, CheckAuthByIP) {
        super();
        this.GetSensors = GetSensors;
        this.GetWeather = GetWeather;
        this.CheckAuthByIP = CheckAuthByIP;
    }
}

export function PortWindAPI_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.PortWind.API.PortWindAPI", [], PortWindAPI, () => [["GetSensors", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(SensorMetaData_$reflection())]))], ["GetWeather", lambda_type(SensorId_$reflection(), lambda_type(class_type("System.TimeSpan"), lambda_type(class_type("System.TimeSpan"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [PortWindWeatherResult_$reflection()]))))], ["CheckAuthByIP", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))]]);
}

export function PortWindAPI_RouteBuilder(_arg, m) {
    return `/api/portwind/${m}`;
}

