import React from "react";
import * as react from "react";
import { map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { NumericTextInput } from "@stenajs-webui/forms";

export function NumericTextInputModule_View(numericTextInputModule_ViewInputProps) {
    const p = numericTextInputModule_ViewInputProps.p;
    let props;
    const id = map((x) => x, FSharpMap__TryFind(p, "id"));
    const value = map((x_1) => x_1, FSharpMap__TryFind(p, "value"));
    const min = map((x_2) => x_2, FSharpMap__TryFind(p, "min"));
    const max = map((x_3) => x_3, FSharpMap__TryFind(p, "max"));
    props = {
        contentRight: map((x_4) => x_4, FSharpMap__TryFind(p, "contentRight")),
        hideButtons: map((x_5) => x_5, FSharpMap__TryFind(p, "hideButtons")),
        id: id,
        max: max,
        min: min,
        onValueChange: map((x_6) => x_6, FSharpMap__TryFind(p, "onValueChange")),
        value: value,
    };
    return react.createElement(NumericTextInput, props);
}

