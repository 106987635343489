import React from "react";
import * as react from "react";
import { map, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Heading } from "@stenajs-webui/core";

export function HeadingModule_View(headingModule_ViewInputProps) {
    const p = headingModule_ViewInputProps.p;
    const props = {
        variant: defaultArg(map((x) => x, FSharpMap__TryFind(p, "variant")), "h1"),
    };
    const children = defaultArg(map((x_1) => x_1, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Heading, props, ...children);
}

