import Vessels$002Emodule from "../../src/StenaWeather.WeatherServiceUI.Client/Pages/Vessels.module.scss";
import { ofArray, length, tryFindBack, where, singleton, empty, map } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { join } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { Record, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { Response_SessionType, Response_VesselInfo_$reflection } from "../StenaWeather.WeatherServiceUI.Shared/Vessels/API.js";
import { record_type, bool_type, union_type, list_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ServerError_$reflection } from "../StenaWeather.WeatherServiceUI.Shared/Errors.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import { PageTitle_title, ErrorView_Cmd_ofError } from "../SharedView.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { onVesselsAPI, Cmd_OfAsync_eitherAsResult } from "../Server.js";
import { comparePrimitives, equals } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { ofList } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty as empty_1, collect, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { TextModule_View } from "../Components/StenaWebUI/Core/Text.js";
import { TextVariant, TextSize } from "../Components/StenaWebUI/Core/Text.js";
import { createElement } from "react";
import React from "react";
import { IndentModule_View } from "../Components/StenaWebUI/Core/Indent.js";
import { SpacingModule_View } from "../Components/StenaWebUI/Core/Spacing.js";
import { ColumnModule_View } from "../Components/StenaWebUI/Core/Column.js";
import { ButtonBase_ViewPrimary, ButtonBase_ViewFlat } from "../Components/StenaWebUI/Elements/Button.js";
import { ButtonSize } from "../Components/StenaWebUI/Elements/Button.js";
import { RowModule_View } from "../Components/StenaWebUI/Core/Row.js";
import { BoxModule_View } from "../Components/StenaWebUI/Core/Box.js";
import { formatToWholeUnits } from "../TimeSpan.js";
import { now, op_Subtraction } from "../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { Page, Router_navigatePage } from "../Router.js";
import { IconModule_View } from "../Components/StenaWebUI/Icons/Icon.js";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { SpaceModule_View } from "../Components/StenaWebUI/Core/Space.js";
import { value as value_42 } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { LoadingScreenModule_View } from "../Components/StenaWebUI/Panels/LoadingScreen.js";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";

export const cn = (() => {
    const styles = Vessels$002Emodule;
    const cnFn = (name) => (styles[name]);
    const cnFns = (names) => map((x) => (styles[x]), names);
    return {
        className: cnFn,
        classes: cnFns,
        prop: {
            className: (arg) => ["className", cnFn(arg)],
            classes: (arg_1) => ["className", join(" ", cnFns(arg_1))],
        },
    };
})();

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LoadVessels", "OnVesselsLoaded"];
    }
}

export function Msg_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.Vessels.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Response_VesselInfo_$reflection()), ServerError_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(Response_VesselInfo_$reflection())]], [["ErrorValue", ServerError_$reflection()]]])]]]);
}

export class State extends Record {
    constructor(Vessels, IsLoading) {
        super();
        this.Vessels = Vessels;
        this.IsLoading = IsLoading;
    }
}

export function State_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.Vessels.State", [], State, () => [["Vessels", list_type(Response_VesselInfo_$reflection())], ["IsLoading", bool_type]]);
}

export const init = [new State(empty(), false), singleton((dispatch) => {
    dispatch(new Msg(0, []));
})];

export function update(msg, state) {
    if (msg.tag === 1) {
        const vessels = msg.fields[0];
        if (vessels.tag === 1) {
            return [new State(state.Vessels, false), ErrorView_Cmd_ofError(vessels.fields[0])];
        }
        else {
            return [new State(vessels.fields[0], false), Cmd_none()];
        }
    }
    else {
        return [new State(state.Vessels, true), Cmd_OfAsync_eitherAsResult(() => onVesselsAPI((x) => x.GetVesselsInfo()), (Item) => (new Msg(1, [Item])))];
    }
}

export function vesselBox(vessel) {
    let p_1_36;
    const visibleSessions = where((f) => equals(f.SessionType, new Response_SessionType(0, [])), vessel.Sessions);
    const historicalSession = tryFindBack((f_1) => equals(f_1.SessionType, new Response_SessionType(1, [])), vessel.Sessions);
    const p_1_37 = ofList(map((value_41) => value_41, ofArray([["shadow", "box"], ["background", (length(visibleSessions) > 0) ? "white" : "#eaeaea"], ["children", singleton((p_1_36 = ofList(map((value_40) => value_40, ofArray([["width", "350px"], ["height", "100%"], ["children", toList(delay(() => {
        let p_1_13, p_1_12, p_1_6, p_1_5, p_1_3, p_1_11, p_1_9;
        return append(singleton_1((p_1_13 = ofList(map((value_13) => value_13, ofArray([["background", "#eaeaea"], ["children", singleton((p_1_12 = ofList(map((value_12) => value_12, ofArray([["justifyContent", "space-between"], ["children", ofArray([(p_1_6 = ofList(map((value_6) => value_6, ofArray([["justifyContent", "center"], ["children", singleton((p_1_5 = ofList(map((value_5) => value_5, singleton(["children", singleton((p_1_3 = ofList(map((value_3) => value_3, singleton(["children", singleton(TextModule_View(ofList(map((value_1) => value_1, ofArray([["children", vessel.VesselName], ["size", new TextSize(0, [])], ["variant", new TextVariant(3, [])]])), {
            Compare: comparePrimitives,
        })))])), {
            Compare: comparePrimitives,
        }), createElement(IndentModule_View, {
            p: p_1_3,
        })))])), {
            Compare: comparePrimitives,
        }), createElement(SpacingModule_View, {
            p: p_1_5,
        })))]])), {
            Compare: comparePrimitives,
        }), createElement(ColumnModule_View, {
            p: p_1_6,
        })), (p_1_11 = ofList(map((value_11) => value_11, singleton(["children", singleton((p_1_9 = ofList(map((value_9) => value_9, singleton(["children", singleton(ButtonBase_ViewFlat(ofList(map((value_7) => value_7, ofArray([["label", "Data consumption"], ["size", new ButtonSize(2, [])]])), {
            Compare: comparePrimitives,
        })))])), {
            Compare: comparePrimitives,
        }), createElement(IndentModule_View, {
            p: p_1_9,
        })))])), {
            Compare: comparePrimitives,
        }), createElement(SpacingModule_View, {
            p: p_1_11,
        }))])]])), {
            Compare: comparePrimitives,
        }), createElement(RowModule_View, {
            p: p_1_12,
        })))]])), {
            Compare: comparePrimitives,
        }), createElement(BoxModule_View, {
            p: p_1_13,
        }))), delay(() => {
            let p_1_23, p_1_22, p_1_35, p_1_34, p_1_27, t_29, p_1_28, p_1_33, p_1_30, p_1_32, p_1_31;
            return (length(visibleSessions) > 0) ? singleton_1((p_1_23 = ofList(map((value_25) => value_25, ofArray([["spacing", 1], ["indent", 1], ["children", singleton((p_1_22 = ofList(map((value_24) => value_24, ofArray([["gap", true], ["justifyContent", "space-between"], ["children", toList(delay(() => {
                let p_1_16;
                return append(singleton_1((p_1_16 = ofList(map((value_17) => value_17, singleton(["children", singleton(TextModule_View(ofList(map((value_15) => value_15, ofArray([["color", "#a8a8a8"], ["children", "Open sessions"]])), {
                    Compare: comparePrimitives,
                })))])), {
                    Compare: comparePrimitives,
                }), createElement(SpacingModule_View, {
                    p: p_1_16,
                }))), delay(() => collect((session) => {
                    let p_1_21, p_1_18, p_1_20, p_1_19;
                    return singleton_1((p_1_21 = ofList(map((value_23) => value_23, ofArray([["height", "100%"], ["justifyContent", "space-between"], ["children", ofArray([(p_1_18 = ofList(map((value_20) => value_20, ofArray([["alignItems", "center"], ["children", singleton(TextModule_View(ofList(map((value_19) => value_19, singleton(["children", `Initiated ${formatToWholeUnits(op_Subtraction(now(), session.CreatedAt))} ago`])), {
                        Compare: comparePrimitives,
                    })))]])), {
                        Compare: comparePrimitives,
                    }), createElement(ColumnModule_View, {
                        p: p_1_18,
                    })), (p_1_20 = ofList(map((value_22) => value_22, ofArray([["justifyContent", "flex-end"], ["children", singleton((p_1_19 = ofList(map((value_21) => value_21, ofArray([["id", "showOnMapButton"], ["label", "Show on map"], ["size", new ButtonSize(2, [])], ["onClick", (_arg) => {
                        Router_navigatePage(new Page(2, [session.SessionId]));
                    }]])), {
                        Compare: comparePrimitives,
                    }), createElement(ButtonBase_ViewPrimary, {
                        p: p_1_19,
                    })))]])), {
                        Compare: comparePrimitives,
                    }), createElement(ColumnModule_View, {
                        p: p_1_20,
                    }))])]])), {
                        Compare: comparePrimitives,
                    }), createElement(RowModule_View, {
                        p: p_1_21,
                    })));
                }, visibleSessions)));
            }))]])), {
                Compare: comparePrimitives,
            }), createElement(ColumnModule_View, {
                p: p_1_22,
            })))]])), {
                Compare: comparePrimitives,
            }), createElement(BoxModule_View, {
                p: p_1_23,
            }))) : singleton_1((p_1_35 = ofList(map((value_39) => value_39, ofArray([["indent", 1], ["spacing", 1], ["height", "100%"], ["children", singleton((p_1_34 = ofList(map((value_38) => value_38, ofArray([["height", "100%"], ["children", ofArray([(p_1_27 = ofList(map((value_30) => value_30, singleton(["children", ofArray([IconModule_View(ofList(map((value_26) => value_26, ofArray([(t_29 = faInfoCircle, ["icon", t_29]), ["size", 15]])), {
                Compare: comparePrimitives,
            })), TextModule_View(ofList(map((value_28) => value_28, ofArray([["children", `${vessel.VesselName} doesn't have any open sessions at this moment`], ["variant", new TextVariant(0, [])]])), {
                Compare: comparePrimitives,
            }))])])), {
                Compare: comparePrimitives,
            }), createElement(IndentModule_View, {
                p: p_1_27,
            })), (p_1_28 = ofList(map((value_31) => value_31, empty()), {
                Compare: comparePrimitives,
            }), createElement(SpaceModule_View, {
                p: p_1_28,
            })), (p_1_33 = ofList(map((value_37) => value_37, ofArray([["justifyContent", "space-between"], ["height", "100%"], ["children", ofArray([(p_1_30 = ofList(map((value_34) => value_34, singleton(["children", toList(delay(() => ((historicalSession != null) ? singleton_1(TextModule_View(ofList(map((value_33) => value_33, singleton(["children", `Initiated ${formatToWholeUnits(op_Subtraction(now(), value_42(historicalSession).CreatedAt))} ago`])), {
                Compare: comparePrimitives,
            }))) : singleton_1(defaultOf()))))])), {
                Compare: comparePrimitives,
            }), createElement(ColumnModule_View, {
                p: p_1_30,
            })), (p_1_32 = ofList(map((value_36) => value_36, ofArray([["justifyContent", "flex-end"], ["children", singleton((p_1_31 = ofList(map((value_35) => value_35, toList(delay(() => append(singleton_1(["id", "showOnMapButton"]), delay(() => append(singleton_1(["label", "Show historical data"]), delay(() => append(singleton_1(["size", new ButtonSize(2, [])]), delay(() => append((historicalSession == null) ? append(singleton_1(["disabled", true]), delay(() => singleton_1(["label", "No historical data"]))) : empty_1(), delay(() => singleton_1(["onClick", (_arg_1) => {
                if (historicalSession != null) {
                    Router_navigatePage(new Page(2, [value_42(historicalSession).SessionId]));
                }
            }])))))))))))), {
                Compare: comparePrimitives,
            }), createElement(ButtonBase_ViewPrimary, {
                p: p_1_31,
            })))]])), {
                Compare: comparePrimitives,
            }), createElement(ColumnModule_View, {
                p: p_1_32,
            }))])]])), {
                Compare: comparePrimitives,
            }), createElement(RowModule_View, {
                p: p_1_33,
            }))])]])), {
                Compare: comparePrimitives,
            }), createElement(ColumnModule_View, {
                p: p_1_34,
            })))]])), {
                Compare: comparePrimitives,
            }), createElement(BoxModule_View, {
                p: p_1_35,
            })));
        }));
    }))]])), {
        Compare: comparePrimitives,
    }), createElement(ColumnModule_View, {
        p: p_1_36,
    })))]])), {
        Compare: comparePrimitives,
    });
    return createElement(BoxModule_View, {
        p: p_1_37,
    });
}

export function VesselsView() {
    const state_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init, update, (_arg, _arg_1) => {
    }), undefined, [])[0];
    const children = toList(delay(() => append(singleton_1(PageTitle_title("List of Vessels", "Choose vessel to display all details or click on current session if exists")), delay(() => {
        let p_1_1, elm;
        return state_1.IsLoading ? singleton_1((p_1_1 = ofList(map((value) => value, singleton(["text", "Loading vessels..."])), {
            Compare: comparePrimitives,
        }), createElement(LoadingScreenModule_View, {
            p: p_1_1,
        }))) : singleton_1((elm = toList(delay(() => map(vesselBox, state_1.Vessels))), createElement("div", {
            className: cn.className("cards"),
            children: reactApi.Children.toArray(Array.from(elm)),
        })));
    }))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

