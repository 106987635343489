import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import * as react from "react";
import { Drawer } from "@stenajs-webui/modal";

export function DrawerModule_view(p) {
    let props;
    const isOpen = map((x) => x, FSharpMap__TryFind(p, "isOpen"));
    const slideFrom = map((x_1) => x_1, FSharpMap__TryFind(p, "slideFrom"));
    const onRequestClose = map((x_2) => x_2, FSharpMap__TryFind(p, "onRequestClose"));
    props = {
        background: map((x_3) => x_3, FSharpMap__TryFind(p, "background")),
        isOpen: isOpen,
        onRequestClose: onRequestClose,
        portalTarget: map((x_4) => x_4, FSharpMap__TryFind(p, "portalTarget")),
        slideFrom: slideFrom,
        width: map((x_5) => x_5, FSharpMap__TryFind(p, "width")),
        zIndex: map((x_6) => x_6, FSharpMap__TryFind(p, "zIndex")),
    };
    const children = defaultArg(map((x_7) => x_7, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Drawer, props, ...children);
}

