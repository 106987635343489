import { Union } from "./fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type } from "./fable_modules/fable-library-js.4.19.3/Reflection.js";
import { append, singleton, empty, tail, head, isEmpty, ofArray } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { WeatherTypeModule_ParameterModule_name, WeatherTypeModule_ParameterModule_parse, WeatherTypeModule_Parameter, WeatherTypeModule_Parameter_$reflection } from "./StenaWeather.Domain/Weather.js";
import { Route_$007CGuid$007C_$007C, Route_$007CQuery$007C_$007C } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { RouterModule_trySeparateLast, RouterModule_encodeQueryString, RouterModule_nav } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { map, defaultArgWith } from "./fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_ofEffect } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";

export class PortWindSubpage extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Table", "Dashboard"];
    }
}

export function PortWindSubpage_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Router.PortWindSubpage", [], PortWindSubpage, () => [[], []]);
}

export function PortWindSubpage_get_Default() {
    return new PortWindSubpage(0, []);
}

export function PortWindSubpageModule_name(_arg) {
    if (_arg.tag === 1) {
        return "dashboard";
    }
    else {
        return "table";
    }
}

export function PortWindSubpageModule_parse(s) {
    switch (s) {
        case "table":
            return new PortWindSubpage(0, []);
        case "dashboard":
            return new PortWindSubpage(1, []);
        default:
            return PortWindSubpage_get_Default();
    }
}

export const PortWindSubpageModule_all = ofArray([new PortWindSubpage(0, []), new PortWindSubpage(1, [])]);

export class Page extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Models", "Vessels", "SessionDetails", "Dashboard", "PortWind", "Logout", "Login", "LoginCallback", "LoginCallbackSilent"];
    }
}

export function Page_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Router.Page", [], Page, () => [[["Item", WeatherTypeModule_Parameter_$reflection()]], [], [["Item", class_type("System.Guid")]], [], [["Item", PortWindSubpage_$reflection()]], [], [], [], []]);
}

export const PageModule_defaultPage = new Page(0, [new WeatherTypeModule_Parameter(0, [])]);

export function PageModule_isLoginPage(_arg) {
    switch (_arg.tag) {
        case 6:
        case 5:
        case 7:
        case 8:
            return true;
        default:
            return false;
    }
}

export function PageModule_parseFromUrlSegments(_arg) {
    let matchResult, p;
    if (!isEmpty(_arg)) {
        switch (head(_arg)) {
            case "logout": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 0;
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "login": {
                if (isEmpty(tail(_arg))) {
                    matchResult = 1;
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "login-callback": {
                if (!isEmpty(tail(_arg))) {
                    if (isEmpty(tail(tail(_arg)))) {
                        matchResult = 2;
                    }
                    else {
                        matchResult = 5;
                    }
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "login-callback-silent": {
                if (!isEmpty(tail(_arg))) {
                    if (isEmpty(tail(tail(_arg)))) {
                        matchResult = 3;
                    }
                    else {
                        matchResult = 5;
                    }
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            case "models": {
                if (!isEmpty(tail(_arg))) {
                    const activePatternResult = Route_$007CQuery$007C_$007C(head(tail(_arg)));
                    if (activePatternResult != null) {
                        if (!isEmpty(activePatternResult)) {
                            if (head(activePatternResult)[0] === "param") {
                                if (isEmpty(tail(activePatternResult))) {
                                    if (isEmpty(tail(tail(_arg)))) {
                                        matchResult = 4;
                                        p = head(activePatternResult)[1];
                                    }
                                    else {
                                        matchResult = 5;
                                    }
                                }
                                else {
                                    matchResult = 5;
                                }
                            }
                            else {
                                matchResult = 5;
                            }
                        }
                        else {
                            matchResult = 5;
                        }
                    }
                    else {
                        matchResult = 5;
                    }
                }
                else {
                    matchResult = 5;
                }
                break;
            }
            default:
                matchResult = 5;
        }
    }
    else {
        matchResult = 5;
    }
    switch (matchResult) {
        case 0:
            return new Page(5, []);
        case 1:
            return new Page(6, []);
        case 2:
            return new Page(7, []);
        case 3:
            return new Page(8, []);
        case 4:
            return new Page(0, [WeatherTypeModule_ParameterModule_parse(p)]);
        default: {
            let matchResult_1, i;
            if (!isEmpty(_arg)) {
                if (head(_arg) === "sessions") {
                    if (!isEmpty(tail(_arg))) {
                        const activePatternResult_1 = Route_$007CGuid$007C_$007C(head(tail(_arg)));
                        if (activePatternResult_1 != null) {
                            if (isEmpty(tail(tail(_arg)))) {
                                matchResult_1 = 0;
                                i = activePatternResult_1;
                            }
                            else {
                                matchResult_1 = 1;
                            }
                        }
                        else {
                            matchResult_1 = 1;
                        }
                    }
                    else {
                        matchResult_1 = 1;
                    }
                }
                else {
                    matchResult_1 = 1;
                }
            }
            else {
                matchResult_1 = 1;
            }
            switch (matchResult_1) {
                case 0:
                    return new Page(2, [i]);
                default: {
                    let matchResult_2, subpage;
                    if (!isEmpty(_arg)) {
                        switch (head(_arg)) {
                            case "vessels": {
                                if (isEmpty(tail(_arg))) {
                                    matchResult_2 = 0;
                                }
                                else {
                                    matchResult_2 = 3;
                                }
                                break;
                            }
                            case "dashboard": {
                                if (isEmpty(tail(_arg))) {
                                    matchResult_2 = 1;
                                }
                                else {
                                    matchResult_2 = 3;
                                }
                                break;
                            }
                            case "portwind": {
                                if (!isEmpty(tail(_arg))) {
                                    const activePatternResult_2 = Route_$007CQuery$007C_$007C(head(tail(_arg)));
                                    if (activePatternResult_2 != null) {
                                        if (!isEmpty(activePatternResult_2)) {
                                            if (head(activePatternResult_2)[0] === "subpage") {
                                                if (isEmpty(tail(activePatternResult_2))) {
                                                    if (isEmpty(tail(tail(_arg)))) {
                                                        matchResult_2 = 2;
                                                        subpage = head(activePatternResult_2)[1];
                                                    }
                                                    else {
                                                        matchResult_2 = 3;
                                                    }
                                                }
                                                else {
                                                    matchResult_2 = 3;
                                                }
                                            }
                                            else {
                                                matchResult_2 = 3;
                                            }
                                        }
                                        else {
                                            matchResult_2 = 3;
                                        }
                                    }
                                    else {
                                        matchResult_2 = 3;
                                    }
                                }
                                else {
                                    matchResult_2 = 3;
                                }
                                break;
                            }
                            default:
                                matchResult_2 = 3;
                        }
                    }
                    else {
                        matchResult_2 = 3;
                    }
                    switch (matchResult_2) {
                        case 0:
                            return new Page(1, []);
                        case 1:
                            return new Page(3, []);
                        case 2:
                            return new Page(4, [PortWindSubpageModule_parse(subpage)]);
                        default: {
                            let matchResult_3;
                            if (!isEmpty(_arg)) {
                                if (head(_arg) === "portwind") {
                                    if (isEmpty(tail(_arg))) {
                                        matchResult_3 = 0;
                                    }
                                    else {
                                        matchResult_3 = 1;
                                    }
                                }
                                else {
                                    matchResult_3 = 1;
                                }
                            }
                            else {
                                matchResult_3 = 1;
                            }
                            switch (matchResult_3) {
                                case 0:
                                    return new Page(4, [PortWindSubpage_get_Default()]);
                                default:
                                    return PageModule_defaultPage;
                            }
                        }
                    }
                }
            }
        }
    }
}

export function PageModule_noQueryString(segments) {
    return [segments, empty()];
}

export function PageModule_toUrlSegments(_arg) {
    switch (_arg.tag) {
        case 6:
            return PageModule_noQueryString(singleton("login"));
        case 7:
            return PageModule_noQueryString(singleton("login-callback"));
        case 8:
            return PageModule_noQueryString(singleton("login-callback-silent"));
        case 0:
            return [singleton("models"), singleton(["param", WeatherTypeModule_ParameterModule_name(_arg.fields[0])])];
        case 2:
            return PageModule_noQueryString(ofArray(["sessions", _arg.fields[0]]));
        case 1:
            return PageModule_noQueryString(singleton("vessels"));
        case 3:
            return PageModule_noQueryString(singleton("dashboard"));
        case 4:
            return [singleton("portwind"), singleton(["subpage", PortWindSubpageModule_name(_arg.fields[0])])];
        default:
            return PageModule_noQueryString(singleton("logout"));
    }
}

export function Router_goToUrl(e) {
    e.preventDefault();
    RouterModule_nav(singleton(e.currentTarget.attributes.href.value), 1, 2);
}

export function Router_navigatePage(p) {
    const tupledArg = PageModule_toUrlSegments(p);
    const queryString = tupledArg[1];
    defaultArgWith(map((tupledArg_1) => {
        RouterModule_nav(append(tupledArg_1[0], singleton(tupledArg_1[1] + RouterModule_encodeQueryString(queryString))), 1, 2);
    }, RouterModule_trySeparateLast(tupledArg[0])), () => {
        RouterModule_nav(singleton(RouterModule_encodeQueryString(queryString)), 1, 2);
    });
}

export function Cmd_navigatePage(p) {
    const tupledArg = PageModule_toUrlSegments(p);
    return Cmd_ofEffect((_arg_1) => {
        const queryString_1 = tupledArg[1];
        defaultArgWith(map((tupledArg_1) => {
            RouterModule_nav(append(tupledArg_1[0], singleton(tupledArg_1[1] + RouterModule_encodeQueryString(queryString_1))), 1, 2);
        }, RouterModule_trySeparateLast(tupledArg[0])), () => {
            RouterModule_nav(singleton(RouterModule_encodeQueryString(queryString_1)), 1, 2);
        });
    });
}

