import React from "react";
import * as react from "react";
import { map, unwrap } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { ToastPositionModule_value } from "./HotToast.js";
import { ofList, FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import { ToastModule_propsToRecord } from "./Toast.js";
import { map as map_1 } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { comparePrimitives } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Toaster } from "react-hot-toast";

export function ToasterModule_View(toasterModule_ViewInputProps) {
    const p = toasterModule_ViewInputProps.p;
    const props = {
        position: unwrap(map(ToastPositionModule_value, map((x) => x, FSharpMap__TryFind(p, "position")))),
        reverseOrder: map((x_1) => x_1, FSharpMap__TryFind(p, "reverseOrder")),
        toastOptions: unwrap(map((arg) => ToastModule_propsToRecord(ofList(map_1((value) => value, arg), {
            Compare: comparePrimitives,
        })), map((x_2) => x_2, FSharpMap__TryFind(p, "toastOptions")))),
    };
    return react.createElement(Toaster, props);
}

