import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { WeatherTypeModule_Parameter_$reflection } from "../../StenaWeather.Domain/Weather.js";
import { ModelUpdateStatus_$reflection, ModelArea_$reflection, ModelKey_$reflection } from "../../StenaWeather.Domain/Models.js";
import { unit_type, lambda_type, bool_type, option_type, class_type, string_type, int32_type, record_type, list_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Provider_$reflection } from "../../StenaWeather.Domain/Forecast.js";

export class Request_UpdatePriority extends Record {
    constructor(Parameter, Models) {
        super();
        this.Parameter = Parameter;
        this.Models = Models;
    }
}

export function Request_UpdatePriority_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Models.API.Request.UpdatePriority", [], Request_UpdatePriority, () => [["Parameter", WeatherTypeModule_Parameter_$reflection()], ["Models", list_type(ModelKey_$reflection())]]);
}

export class Response_Model extends Record {
    constructor(Priority, DataSourceDescription, Provider, ModelName, SpatialResolution, SpatialResolutionInKm, TemporalResolution, Area, TemporalRange, UpdateFrequency, LastUpdated, ForecastCreated, SourceType, IsRotated, Status) {
        super();
        this.Priority = (Priority | 0);
        this.DataSourceDescription = DataSourceDescription;
        this.Provider = Provider;
        this.ModelName = ModelName;
        this.SpatialResolution = SpatialResolution;
        this.SpatialResolutionInKm = SpatialResolutionInKm;
        this.TemporalResolution = TemporalResolution;
        this.Area = Area;
        this.TemporalRange = TemporalRange;
        this.UpdateFrequency = UpdateFrequency;
        this.LastUpdated = LastUpdated;
        this.ForecastCreated = ForecastCreated;
        this.SourceType = SourceType;
        this.IsRotated = IsRotated;
        this.Status = Status;
    }
}

export function Response_Model_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Models.API.Response.Model", [], Response_Model, () => [["Priority", int32_type], ["DataSourceDescription", string_type], ["Provider", Provider_$reflection()], ["ModelName", string_type], ["SpatialResolution", string_type], ["SpatialResolutionInKm", string_type], ["TemporalResolution", class_type("System.TimeSpan")], ["Area", ModelArea_$reflection()], ["TemporalRange", class_type("System.TimeSpan")], ["UpdateFrequency", class_type("System.TimeSpan")], ["LastUpdated", option_type(class_type("System.DateTimeOffset"))], ["ForecastCreated", option_type(class_type("System.DateTimeOffset"))], ["SourceType", string_type], ["IsRotated", bool_type], ["Status", ModelUpdateStatus_$reflection()]]);
}

export class ModelsAPI extends Record {
    constructor(GetModels, SavePriorities) {
        super();
        this.GetModels = GetModels;
        this.SavePriorities = SavePriorities;
    }
}

export function ModelsAPI_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Models.API.ModelsAPI", [], ModelsAPI, () => [["GetModels", lambda_type(WeatherTypeModule_Parameter_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(Response_Model_$reflection())]))], ["SavePriorities", lambda_type(Request_UpdatePriority_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [unit_type]))]]);
}

export function ModelsAPI_RouteBuilder(_arg, m) {
    return `/api/models/${m}`;
}

