import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import * as react from "react";
import { Modal } from "@stenajs-webui/modal";

export function ModalModule_view(p) {
    let props;
    const isOpen = map((x) => x, FSharpMap__TryFind(p, "isOpen"));
    const headerText = map((x_1) => x_1, FSharpMap__TryFind(p, "headerText"));
    const width = map((x_2) => x_2, FSharpMap__TryFind(p, "width"));
    props = {
        headerText: headerText,
        isOpen: isOpen,
        onRequestClose: map((x_3) => x_3, FSharpMap__TryFind(p, "onRequestClose")),
        width: width,
    };
    const children = defaultArg(map((x_4) => x_4, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Modal, props, ...children);
}

