import React from "react";
import * as react from "react";
import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { SidebarMenuLink, SidebarMenuHeading, SidebarMenuCollapsible } from "@stenajs-webui/panels";

export function SidebarMenuCollapsible_View(sidebarMenuCollapsible_ViewInputProps) {
    const p = sidebarMenuCollapsible_ViewInputProps.p;
    const props = {
        label: map((x) => x, FSharpMap__TryFind(p, "label")),
        leftIcon: map((x_1) => x_1, FSharpMap__TryFind(p, "leftIcon")),
    };
    const children = defaultArg(map((x_2) => x_2, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(SidebarMenuCollapsible, props, ...children);
}

export function SidebarMenuHeading_View(sidebarMenuHeading_ViewInputProps) {
    const p = sidebarMenuHeading_ViewInputProps.p;
    const props = {
        label: map((x) => x, FSharpMap__TryFind(p, "label")),
    };
    return react.createElement(SidebarMenuHeading, props);
}

export function SidebarMenuLink_View(sidebarMenuLink_ViewInputProps) {
    const p = sidebarMenuLink_ViewInputProps.p;
    let props;
    const label = map((x) => x, FSharpMap__TryFind(p, "label"));
    const id = map((x_1) => x_1, FSharpMap__TryFind(p, "id"));
    const loading = map((x_2) => x_2, FSharpMap__TryFind(p, "loading"));
    const selected = map((x_3) => x_3, FSharpMap__TryFind(p, "selected"));
    const indent = map((x_4) => x_4, FSharpMap__TryFind(p, "indent"));
    const leftIcon = map((x_5) => x_5, FSharpMap__TryFind(p, "leftIcon"));
    props = {
        id: id,
        indent: indent,
        label: label,
        left: map((x_6) => x_6, FSharpMap__TryFind(p, "left")),
        leftIcon: leftIcon,
        loading: loading,
        onClick: map((x_7) => x_7, FSharpMap__TryFind(p, "onClick")),
        selected: selected,
    };
    return react.createElement(SidebarMenuLink, props);
}

