import { FSharpException, Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class ServerError extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Exception", "ServiceError", "Authentication", "Authorization", "Domain"];
    }
}

export function ServerError_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Shared.Errors.ServerError", [], ServerError, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]]]);
}

export class ServerException extends FSharpException {
    constructor(Data0) {
        super();
        this.Data0 = Data0;
    }
}

export function ServerException_$reflection() {
    return class_type("StenaWeather.WeatherServiceUI.Shared.Errors.ServerException", undefined, ServerException, class_type("System.Exception"));
}

export function ServerErrorModule_failwith(er) {
    throw new ServerException(er);
}

export function ServerErrorModule_ofResult(v) {
    if (v.tag === 1) {
        return ServerErrorModule_failwith(v.fields[0]);
    }
    else {
        return v.fields[0];
    }
}

export function ServerErrorModule_title(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Service Error";
        case 2:
            return "Authentication Error";
        case 3:
            return "Authorization Error";
        case 4:
            return "Domain Error";
        default:
            return "General Exception";
    }
}

export function ServerErrorModule_text(_arg) {
    let t;
    switch (_arg.tag) {
        case 1: {
            t = _arg.fields[0];
            break;
        }
        case 2: {
            t = _arg.fields[0];
            break;
        }
        case 3: {
            t = _arg.fields[0];
            break;
        }
        case 4: {
            t = _arg.fields[0];
            break;
        }
        default:
            t = _arg.fields[0];
    }
    return t;
}

