import React from "react";
import * as react from "react";
import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { SeparatorLine } from "@stenajs-webui/core";

export function SeparatorLineModule_View(separatorLineModule_ViewInputProps) {
    const p = separatorLineModule_ViewInputProps.p;
    let props;
    const color = map((x) => x, FSharpMap__TryFind(p, "color"));
    const width = map((x_1) => x_1, FSharpMap__TryFind(p, "width"));
    const vertical = map((x_2) => x_2, FSharpMap__TryFind(p, "vertical"));
    props = {
        color: color,
        size: map((x_3) => x_3, FSharpMap__TryFind(p, "size")),
        vertical: vertical,
        width: width,
    };
    const children = defaultArg(map((x_4) => x_4, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(SeparatorLine, props, ...children);
}

