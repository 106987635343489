import { Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { lambda_type, unit_type, list_type, string_type, record_type, class_type, union_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class Response_SessionType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Open", "Closed"];
    }
}

export function Response_SessionType_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Shared.Vessels.API.Response.SessionType", [], Response_SessionType, () => [[], []]);
}

export class Response_VesselSession extends Record {
    constructor(SessionId, CreatedAt, SessionType) {
        super();
        this.SessionId = SessionId;
        this.CreatedAt = CreatedAt;
        this.SessionType = SessionType;
    }
}

export function Response_VesselSession_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Vessels.API.Response.VesselSession", [], Response_VesselSession, () => [["SessionId", class_type("System.Guid")], ["CreatedAt", class_type("System.DateTimeOffset")], ["SessionType", Response_SessionType_$reflection()]]);
}

export class Response_VesselInfo extends Record {
    constructor(VesselId, VesselName, Sessions) {
        super();
        this.VesselId = VesselId;
        this.VesselName = VesselName;
        this.Sessions = Sessions;
    }
}

export function Response_VesselInfo_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Vessels.API.Response.VesselInfo", [], Response_VesselInfo, () => [["VesselId", class_type("System.Guid")], ["VesselName", string_type], ["Sessions", list_type(Response_VesselSession_$reflection())]]);
}

export class VesselsAPI extends Record {
    constructor(GetVesselsInfo) {
        super();
        this.GetVesselsInfo = GetVesselsInfo;
    }
}

export function VesselsAPI_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.Vessels.API.VesselsAPI", [], VesselsAPI, () => [["GetVesselsInfo", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(Response_VesselInfo_$reflection())]))]]);
}

export function VesselsAPI_RouteBuilder(_arg, m) {
    return `/api/vessels/${m}`;
}

