import React from "react";
import * as react from "react";
import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Indent } from "@stenajs-webui/core";

export function IndentModule_View(indentModule_ViewInputProps) {
    const p = indentModule_ViewInputProps.p;
    const props = {
        num: map((x) => x, FSharpMap__TryFind(p, "num")),
    };
    const children = defaultArg(map((x_1) => x_1, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Indent, props, ...children);
}

