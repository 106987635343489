import React from "react";
import * as react from "react";
import { map, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { SwitchWithLabel } from "@stenajs-webui/forms";

export function SwitchWithLabelModule_View(switchWithLabelModule_ViewInputProps) {
    const p = switchWithLabelModule_ViewInputProps.p;
    let props;
    const label = defaultArg(map((x) => x, FSharpMap__TryFind(p, "label")), "Label");
    const textColor = map((x_1) => x_1, FSharpMap__TryFind(p, "textColor"));
    const value = map((x_2) => x_2, FSharpMap__TryFind(p, "value"));
    props = {
        label: label,
        onValueChange: map((x_3) => x_3, FSharpMap__TryFind(p, "onValueChange")),
        textColor: textColor,
        value: value,
    };
    return react.createElement(SwitchWithLabel, props);
}

