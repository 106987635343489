import { hours, minutes, days, totalDays, totalHours, totalMinutes, totalSeconds } from "./fable_modules/fable-library-js.4.19.3/TimeSpan.js";

export function formatToWholeUnits(sp) {
    const ts = ~~totalSeconds(sp) | 0;
    const tm = ~~totalMinutes(sp) | 0;
    const th = ~~totalHours(sp) | 0;
    const td = ~~totalDays(sp) | 0;
    const matchValue = ts < 60;
    const matchValue_1 = tm < 60;
    const matchValue_2 = th < 24;
    if (matchValue) {
        if (ts > 1) {
            return `${ts} seconds`;
        }
        else {
            return `${ts} second`;
        }
    }
    else if (matchValue_1) {
        if (tm > 1) {
            return `${tm} minutes`;
        }
        else {
            return `${tm} minute`;
        }
    }
    else if (matchValue_2) {
        if (th > 1) {
            return `${th} hours`;
        }
        else {
            return `${th} hour`;
        }
    }
    else if (td > 1) {
        return `${td} days`;
    }
    else {
        return `${td} day`;
    }
}

export function formatToMultipleUnits(sp) {
    const d = days(sp) | 0;
    const m = minutes(sp) | 0;
    const h = hours(sp) | 0;
    if (d > 0) {
        return `${d}d ${h}h ${m}min`;
    }
    else if (h > 0) {
        return `${h}h ${m}min`;
    }
    else {
        return `${m}min`;
    }
}

