import React from "react";
import * as react from "react";
import { map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { Banner as Banner_1 } from "@stenajs-webui/elements";
import { class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";

export function BannerModule_View(bannerModule_ViewInputProps) {
    const p = bannerModule_ViewInputProps.p;
    let props;
    const icon = map((x) => x, FSharpMap__TryFind(p, "icon"));
    const headerText = map((x_1) => x_1, FSharpMap__TryFind(p, "headerText"));
    const text = map((x_2) => x_2, FSharpMap__TryFind(p, "text"));
    const loading = map((x_3) => x_3, FSharpMap__TryFind(p, "loading"));
    props = {
        contentRight: map((x_4) => x_4, FSharpMap__TryFind(p, "contentRight")),
        headerText: headerText,
        icon: icon,
        loading: loading,
        text: text,
        variant: map((x_5) => x_5, FSharpMap__TryFind(p, "variant")),
    };
    return react.createElement(Banner_1, props);
}

export class Banner {
    constructor() {
    }
}

export function Banner_$reflection() {
    return class_type("StenaWeather.WeatherServiceUI.Client.Components.StenaWebUI.Elements.Banner", undefined, Banner);
}

