import { printf, toText } from "./fable_modules/fable-library-js.4.19.3/String.js";
import { value as value_43, defaultArgWith, map, defaultArg, ofNullable, some } from "./fable_modules/fable-library-js.4.19.3/Option.js";
import { Convert_fromJson, Convert_serialize } from "./fable_modules/Fable.SimpleJson.3.24.0/Json.Converter.fs.js";
import { createTypeInfo } from "./fable_modules/Fable.SimpleJson.3.24.0/TypeInfo.Converter.fs.js";
import { Router_goToUrl, PageModule_toUrlSegments, PortWindSubpage, Router_navigatePage, Cmd_navigatePage, PageModule_parseFromUrlSegments, PortWindSubpageModule_all, Page, PageModule_isLoginPage, PageModule_defaultPage, Page_$reflection } from "./Router.js";
import { SimpleJson_tryParse } from "./fable_modules/Fable.SimpleJson.3.24.0/SimpleJson.fs.js";
import { Record, Union } from "./fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, union_type, list_type } from "./fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Response_UserProfile_$reflection } from "./StenaWeather.WeatherServiceUI.Shared/Profiles/API.js";
import { ServerError_$reflection } from "./StenaWeather.WeatherServiceUI.Shared/Errors.js";
import { Result_Map, FSharpResult$2 } from "./fable_modules/fable-library-js.4.19.3/Result.js";
import { append as append_1, singleton, ofArray, contains, map as map_1 } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { RouterModule_trySeparateLast, RouterModule_encodeQueryString, RouterModule_encodeParts, RouterModule_router, RouterModule_urlSegments } from "./fable_modules/Feliz.Router.4.0.0/Router.fs.js";
import { Cmd_OfPromise_either, Cmd_ofEffect, Cmd_none, Cmd_batch, Cmd_OfPromise_perform } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { onProfilesAPI, onPortWindAPI, Cmd_OfAsync_eitherAsResult, Auth_userManager } from "./Server.js";
import { createObj, comparePrimitives, safeHash, equals } from "./fable_modules/fable-library-js.4.19.3/Util.js";
import { noCmd, withCmd } from "./UseElmish.js";
import sws_white_vertical from "../src/StenaWeather.WeatherServiceUI.Client/images/sws_white_vertical.svg";
import { ofList } from "./fable_modules/fable-library-js.4.19.3/Map.js";
import { IconModule_View } from "./Components/StenaWebUI/Icons/Icon.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { BoxModule_View } from "./Components/StenaWebUI/Core/Box.js";
import { empty, singleton as singleton_1, append, delay, toList } from "./fable_modules/fable-library-js.4.19.3/Seq.js";
import { SidebarMenuCollapsible_View, SidebarMenuHeading_View, SidebarMenuLink_View } from "./Components/StenaWebUI/Panels/SidebarMenu.js";
import { ToastPosition } from "./Components/HotToast/HotToast.js";
import { fromSeconds } from "./fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { ToasterModule_View } from "./Components/HotToast/Toaster.js";
import { CurrentSettingsDisplay } from "./Components/TimeZones/Components.js";
import { faCloudRain, faTemperatureHigh, faCloud, faMoon, faWater, faWind, faAnchor, faChartPie } from "@fortawesome/free-solid-svg-icons";
import { WeatherTypeModule_Parameter } from "./StenaWeather.Domain/Weather.js";
import { faCustomCurrentIcon } from "../src/StenaWeather.WeatherServiceUI.Client/Components/StenaWebUI/Icons/CurrentIcon.js";
import { SidebarMenu } from "@stenajs-webui/panels";
import { reactApi } from "./fable_modules/Feliz.2.8.0/Interop.fs.js";
import { saveToStorage, getDefaultUserSettings, tryGetFromStorage } from "./Components/TimeZones/Storage.js";
import { TimeZoneContext, TimezoneContext } from "./Components/TimeZones/Context.js";
import { defaultOf } from "./fable_modules/fable-library-js.4.19.3/Util.js";
import { ModelsView } from "./Pages/Models.js";
import { VesselsView } from "./Pages/Vessels.js";
import { SessionDetailsView } from "./Pages/SessionDetails.js";
import { DashboardView } from "./Pages/Dashboard.js";
import { PortWindDashboardView } from "./Pages/PortWindDashboard.js";
import { PortWindView } from "./Pages/PortWind.js";
import { LoadingScreenModule_View } from "./Components/StenaWebUI/Panels/LoadingScreen.js";

export function SessionStorage_setItem(key, value) {
    window.sessionStorage.setItem(key, value);
    console.log(some(toText(printf("Set item: %s -> %s"))(key)(value)));
}

export function SessionStorage_getItem(key) {
    const x = ofNullable(window.sessionStorage.getItem(key));
    console.log(some(toText(printf("Get item: %s -> %A"))(key)(x)));
    return x;
}

export function SessionStorage_setLoginReturnPage(page) {
    SessionStorage_setItem("loginReturnPage", Convert_serialize(page, createTypeInfo(Page_$reflection())));
}

export function SessionStorage_getLoginReturnPage() {
    return defaultArg(map((input) => {
        const matchValue = SimpleJson_tryParse(input);
        if (matchValue != null) {
            return Convert_fromJson(matchValue, createTypeInfo(Page_$reflection()));
        }
        else {
            throw new Error("Couldn\'t parse the input JSON string because it seems to be invalid");
        }
    }, SessionStorage_getItem("loginReturnPage")), PageModule_defaultPage);
}

export class LoginStep extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["InitAuthentication", "Authenticate", "LoadProfile", "AuthorizedLimitedAccess", "ProfileLoaded"];
    }
}

export function LoginStep_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.View.LoginStep", [], LoginStep, () => [[], [], [], [["Item", list_type(Page_$reflection())]], [["Item", Response_UserProfile_$reflection()]]]);
}

export class AuthState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["InProgress", "Unauthorized", "LimitedAccess", "LoggedIn"];
    }
}

export function AuthState_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.View.AuthState", [], AuthState, () => [[], [], [["Item", list_type(Page_$reflection())]], [["Item", Response_UserProfile_$reflection()]]]);
}

export class State extends Record {
    constructor(Page, Auth) {
        super();
        this.Page = Page;
        this.Auth = Auth;
    }
}

export function State_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.View.State", [], State, () => [["Page", Page_$reflection()], ["Auth", AuthState_$reflection()]]);
}

export function changePage(page, state) {
    if (!PageModule_isLoginPage(state.Page)) {
        SessionStorage_setLoginReturnPage(page);
    }
    return new State(page, state.Auth);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UrlChanged", "NextLoginStep"];
    }
}

export function Msg_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.View.Msg", [], Msg, () => [[["Item", Page_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [LoginStep_$reflection(), ServerError_$reflection()], FSharpResult$2, () => [[["ResultValue", LoginStep_$reflection()]], [["ErrorValue", ServerError_$reflection()]]])]]]);
}

export function init() {
    const pagesWithIpAccess = map_1((Item) => (new Page(4, [Item])), PortWindSubpageModule_all);
    const nextPage = PageModule_parseFromUrlSegments(RouterModule_urlSegments(window.location.pathname + window.location.search, 2));
    let cmd_1;
    switch (nextPage.tag) {
        case 7: {
            cmd_1 = Cmd_OfPromise_perform(() => Auth_userManager.signinRedirectCallback(), undefined, (_arg_1) => (new Msg(1, [new FSharpResult$2(0, [new LoginStep(2, [])])])));
            break;
        }
        case 8: {
            cmd_1 = Cmd_OfPromise_perform(() => Auth_userManager.signinSilentCallback(), undefined, (_arg_2) => (new Msg(1, [new FSharpResult$2(0, [new LoginStep(2, [])])])));
            break;
        }
        case 5: {
            cmd_1 = Cmd_OfPromise_perform(() => Auth_userManager.signinRedirect(), undefined, () => (new Msg(0, [new Page(5, [])])));
            break;
        }
        default:
            if (contains(nextPage, pagesWithIpAccess, {
                Equals: equals,
                GetHashCode: safeHash,
            })) {
                const cmd = Cmd_OfAsync_eitherAsResult(() => onPortWindAPI((x_1) => x_1.CheckAuthByIP()), (arg) => (new Msg(1, [Result_Map(() => (new LoginStep(3, [pagesWithIpAccess])), arg)])));
                cmd_1 = Cmd_batch(ofArray([Cmd_navigatePage(nextPage), cmd]));
            }
            else {
                cmd_1 = Cmd_batch(ofArray([Cmd_navigatePage(nextPage), singleton((dispatch) => {
                    dispatch(new Msg(1, [new FSharpResult$2(0, [new LoginStep(0, [])])]));
                })]));
            }
    }
    return [changePage(nextPage, new State(nextPage, new AuthState(0, []))), cmd_1];
}

export function update(msg, state) {
    const matchValue = state.Auth;
    let matchResult, page_3, pages_3, page_4, page_5, e, step;
    if (msg.tag === 1) {
        if (msg.fields[0].tag === 0) {
            matchResult = 7;
            step = msg.fields[0].fields[0];
        }
        else {
            matchResult = 6;
            e = msg.fields[0].fields[0];
        }
    }
    else {
        switch (msg.fields[0].tag) {
            case 8:
            case 7: {
                matchResult = 0;
                break;
            }
            case 6: {
                if (matchValue.tag === 2) {
                    if (contains(msg.fields[0], matchValue.fields[0], {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })) {
                        matchResult = 1;
                        page_3 = msg.fields[0];
                        pages_3 = matchValue.fields[0];
                    }
                    else {
                        matchResult = 2;
                        page_4 = msg.fields[0];
                    }
                }
                else {
                    matchResult = 3;
                }
                break;
            }
            case 5: {
                if (matchValue.tag === 2) {
                    if (contains(msg.fields[0], matchValue.fields[0], {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })) {
                        matchResult = 1;
                        page_3 = msg.fields[0];
                        pages_3 = matchValue.fields[0];
                    }
                    else {
                        matchResult = 2;
                        page_4 = msg.fields[0];
                    }
                }
                else {
                    matchResult = 4;
                }
                break;
            }
            default:
                if (matchValue.tag === 2) {
                    if (contains(msg.fields[0], matchValue.fields[0], {
                        Equals: equals,
                        GetHashCode: safeHash,
                    })) {
                        matchResult = 1;
                        page_3 = msg.fields[0];
                        pages_3 = matchValue.fields[0];
                    }
                    else {
                        matchResult = 2;
                        page_4 = msg.fields[0];
                    }
                }
                else {
                    matchResult = 5;
                    page_5 = msg.fields[0];
                }
        }
    }
    switch (matchResult) {
        case 0:
            return [state, Cmd_none()];
        case 1:
            return [changePage(page_3, state), Cmd_none()];
        case 2: {
            const v = changePage(page_4, new State(state.Page, new AuthState(0, [])));
            return withCmd(Cmd_OfPromise_perform(() => Auth_userManager.signinRedirect(), undefined, () => (new Msg(0, [SessionStorage_getLoginReturnPage()]))), v);
        }
        case 3: {
            const v_1 = new State(state.Page, new AuthState(0, []));
            return withCmd(Cmd_OfPromise_perform(() => Auth_userManager.signinRedirect(), undefined, () => (new Msg(0, [SessionStorage_getLoginReturnPage()]))), v_1);
        }
        case 4: {
            const v_2 = new State(state.Page, new AuthState(0, []));
            return withCmd(Cmd_OfPromise_perform(() => Auth_userManager.signoutRedirect(), undefined, () => (new Msg(0, [PageModule_defaultPage]))), v_2);
        }
        case 5:
            return [changePage(page_5, state), Cmd_none()];
        case 6:
            if (e.tag === 3) {
                return noCmd(new State(state.Page, new AuthState(1, [])));
            }
            else {
                const v_4 = new State(state.Page, new AuthState(0, []));
                return withCmd(Cmd_navigatePage(new Page(6, [])), v_4);
            }
        default:
            switch (step.tag) {
                case 1:
                    return withCmd(Cmd_ofEffect((_arg_5) => {
                        Auth_userManager.signinRedirect();
                    }), state);
                case 2:
                    return withCmd(Cmd_OfAsync_eitherAsResult(() => onProfilesAPI((x_3) => x_3.GetProfile()), (arg_1) => (new Msg(1, [Result_Map((Item_2) => (new LoginStep(4, [Item_2])), arg_1)]))), state);
                case 3:
                    return noCmd(new State(state.Page, new AuthState(2, [step.fields[0]])));
                case 4: {
                    const state_1 = new State(state.Page, new AuthState(3, [step.fields[0]]));
                    if (state_1.Page.tag === 7) {
                        const page_6 = SessionStorage_getLoginReturnPage();
                        const v_9 = new State(page_6, state_1.Auth);
                        return withCmd(Cmd_navigatePage(page_6), v_9);
                    }
                    else {
                        return noCmd(state_1);
                    }
                }
                default: {
                    const onError = (_arg_3) => (new Msg(1, [new FSharpResult$2(0, [new LoginStep(1, [])])]));
                    return withCmd(Cmd_OfPromise_either(() => Auth_userManager.getUser(), undefined, (arg) => defaultArgWith(map((_arg_4) => (new Msg(1, [new FSharpResult$2(0, [new LoginStep(2, [])])])), arg), onError), onError), state);
                }
            }
    }
}

function inLayout(currentPage, content) {
    const logoPath = sws_white_vertical;
    const link = (lbl, ic, nextPage) => {
        const iconObj = map((i) => {
            const p_1_2 = ofList(map_1((value_1) => value_1, singleton(["children", singleton(IconModule_View(ofList(map_1((value) => value, ofArray([["size", 13], ["icon", i], ["color", "var(--lhds-color-ui-100)"]])), {
                Compare: comparePrimitives,
            })))])), {
                Compare: comparePrimitives,
            });
            return createElement(BoxModule_View, {
                p: p_1_2,
            });
        }, ic);
        const isSelected = (currentPage.tag === 2) ? ((nextPage.tag === 1) ? true : equals(currentPage, nextPage)) : equals(currentPage, nextPage);
        const p_1_3 = ofList(map_1((value_2) => value_2, toList(delay(() => append(singleton_1(["onClick", () => {
            Router_navigatePage(nextPage);
        }]), delay(() => append(singleton_1(["id", `menu${lbl}`]), delay(() => append(singleton_1(["label", lbl]), delay(() => append(isSelected ? singleton_1(["selected", true]) : empty(), delay(() => ((iconObj != null) ? singleton_1(["left", value_43(iconObj)]) : singleton_1(["indent", true]))))))))))))), {
            Compare: comparePrimitives,
        });
        return createElement(SidebarMenuLink_View, {
            p: p_1_3,
        });
    };
    const elm = toList(delay(() => {
        let p_1_4;
        return append(singleton_1((p_1_4 = ofList(map_1((value_6) => value_6, ofArray([["position", new ToastPosition(2, [])], ["toastOptions", singleton(["duration", ~~fromSeconds(4)])]])), {
            Compare: comparePrimitives,
        }), createElement(ToasterModule_View, {
            p: p_1_4,
        }))), delay(() => {
            let p_1_5, elm_4, elm_6, children, p_1_6, p_1_7, p_1_8, p_1_9, p_1_10, props_16;
            const timezone = createElement("div", {
                className: "timezone-display-containter",
                children: (p_1_5 = ofList(map_1((value_11) => value_11, ofArray([["spacing", 2], ["indent", 4], ["background", "--lhds-color-blue-900"], ["children", singleton(createElement(CurrentSettingsDisplay, null))]])), {
                    Compare: comparePrimitives,
                }), createElement(BoxModule_View, {
                    p: p_1_5,
                })),
            });
            return append(singleton_1((elm_4 = ofArray([(elm_6 = singleton((children = [createElement("div", {
                className: "menu-logo",
                children: createElement("img", {
                    src: logoPath,
                }),
            }), (p_1_6 = ofList(map_1((value_24) => value_24, singleton(["label", "APPLICATIONS"])), {
                Compare: comparePrimitives,
            }), createElement(SidebarMenuHeading_View, {
                p: p_1_6,
            })), link("Dashboard", faChartPie, new Page(3, [])), link("Vessels", faAnchor, new Page(1, [])), (p_1_7 = ofList(map_1((value_25) => value_25, ofArray([["label", "PortWind"], ["leftIcon", faWind], ["children", ofArray([link("Table", undefined, new Page(4, [new PortWindSubpage(0, [])])), link("Dashboard", undefined, new Page(4, [new PortWindSubpage(1, [])]))])]])), {
                Compare: comparePrimitives,
            }), createElement(SidebarMenuCollapsible_View, {
                p: p_1_7,
            })), (p_1_8 = ofList(map_1((value_26) => value_26, singleton(["label", "FORECAST MODELS"])), {
                Compare: comparePrimitives,
            }), createElement(SidebarMenuHeading_View, {
                p: p_1_8,
            })), (p_1_9 = ofList(map_1((value_27) => value_27, ofArray([["label", "Wind"], ["leftIcon", faWind], ["children", ofArray([link("Wind 10m", undefined, new Page(0, [new WeatherTypeModule_Parameter(0, [])])), link("Wind 50m", undefined, new Page(0, [new WeatherTypeModule_Parameter(1, [])]))])]])), {
                Compare: comparePrimitives,
            }), createElement(SidebarMenuCollapsible_View, {
                p: p_1_9,
            })), link("Current", faCustomCurrentIcon, new Page(0, [new WeatherTypeModule_Parameter(2, [])])), (p_1_10 = ofList(map_1((value_28) => value_28, ofArray([["label", "Waves"], ["leftIcon", faWater], ["children", ofArray([link("Waves", undefined, new Page(0, [new WeatherTypeModule_Parameter(3, [])])), link("Wind Waves", undefined, new Page(0, [new WeatherTypeModule_Parameter(7, [])])), link("Primary Swell", undefined, new Page(0, [new WeatherTypeModule_Parameter(5, [])])), link("Secondary Swell", undefined, new Page(0, [new WeatherTypeModule_Parameter(6, [])]))])]])), {
                Compare: comparePrimitives,
            }), createElement(SidebarMenuCollapsible_View, {
                p: p_1_10,
            })), link("Tide", faMoon, new Page(0, [new WeatherTypeModule_Parameter(4, [])])), link("Cloud cover", faCloud, new Page(0, [new WeatherTypeModule_Parameter(8, [])])), link("Air Temperature", faTemperatureHigh, new Page(0, [new WeatherTypeModule_Parameter(9, [])])), link("Precipitation", faCloudRain, new Page(0, [new WeatherTypeModule_Parameter(10, [])]))], (props_16 = {
                hideCloseButton: true,
            }, react.createElement(SidebarMenu, props_16, ...children)))), createElement("div", {
                className: "menu-content",
                children: reactApi.Children.toArray(Array.from(elm_6)),
            })), timezone]), createElement("div", {
                className: "menu",
                children: reactApi.Children.toArray(Array.from(elm_4)),
            }))), delay(() => {
                let elems_3;
                return singleton_1(createElement("div", createObj(ofArray([["style", {
                    overflowX: "hidden",
                    width: 100 + "%",
                }], (elems_3 = [createElement("div", {
                    className: "content",
                    children: createElement("div", {
                        className: "content-wrapper",
                        children: content,
                    }),
                })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
            }));
        }));
    }));
    return createElement("div", {
        className: "layout",
        children: reactApi.Children.toArray(Array.from(elm)),
    });
}

export function MainView(mainViewInputProps) {
    let elements_1, matchValue_1, xs_3, tupledArg, queryString, matchValue, p_1_1;
    const dispatch = mainViewInputProps.dispatch;
    const state = mainViewInputProps.state;
    const timezoneSettingsFromStorage = defaultArgWith(tryGetFromStorage(), getDefaultUserSettings);
    const patternInput = reactApi.useState(timezoneSettingsFromStorage);
    const timeZoneContext = new TimezoneContext(patternInput[0], (ctx) => {
        saveToStorage(ctx);
        patternInput[1](ctx);
    });
    return RouterModule_router(createObj(ofArray([["hashMode", 2], ["onUrlChanged", (arg_1) => {
        dispatch(new Msg(0, [PageModule_parseFromUrlSegments(arg_1)]));
    }], (elements_1 = singleton(createElement(TimeZoneContext, {
        ctx: timeZoneContext,
        children: (matchValue_1 = state.Auth, (matchValue_1.tag === 1) ? ((xs_3 = [createElement("div", {
            children: ["️⚠️ Insufficient permissions."],
        }), createElement("a", {
            href: (tupledArg = PageModule_toUrlSegments(new Page(5, [])), (queryString = tupledArg[1], defaultArgWith(map((tupledArg_1) => RouterModule_encodeParts(append_1(tupledArg_1[0], singleton(tupledArg_1[1] + RouterModule_encodeQueryString(queryString))), 2), RouterModule_trySeparateLast(tupledArg[0])), () => RouterModule_encodeParts(singleton(RouterModule_encodeQueryString(queryString)), 2)))),
            onClick: (e) => {
                Router_goToUrl(e);
            },
            children: "Logout",
        })], react.createElement(react.Fragment, {}, ...xs_3))) : ((matchValue_1.tag === 2) ? ((matchValue = state.Page, (matchValue.tag === 7) ? defaultOf() : ((matchValue.tag === 5) ? defaultOf() : ((matchValue.tag === 6) ? defaultOf() : ((matchValue.tag === 0) ? inLayout(state.Page, createElement(ModelsView, {
            p: matchValue.fields[0],
        })) : ((matchValue.tag === 1) ? inLayout(state.Page, createElement(VesselsView, null)) : ((matchValue.tag === 2) ? inLayout(state.Page, createElement(SessionDetailsView, {
            sessionId: matchValue.fields[0],
        })) : ((matchValue.tag === 3) ? inLayout(state.Page, createElement(DashboardView, null)) : ((matchValue.tag === 4) ? ((matchValue.fields[0].tag === 1) ? inLayout(state.Page, createElement(PortWindDashboardView, null)) : inLayout(state.Page, createElement(PortWindView, null))) : defaultOf()))))))))) : ((matchValue_1.tag === 3) ? ((matchValue = state.Page, (matchValue.tag === 7) ? defaultOf() : ((matchValue.tag === 5) ? defaultOf() : ((matchValue.tag === 6) ? defaultOf() : ((matchValue.tag === 0) ? inLayout(state.Page, createElement(ModelsView, {
            p: matchValue.fields[0],
        })) : ((matchValue.tag === 1) ? inLayout(state.Page, createElement(VesselsView, null)) : ((matchValue.tag === 2) ? inLayout(state.Page, createElement(SessionDetailsView, {
            sessionId: matchValue.fields[0],
        })) : ((matchValue.tag === 3) ? inLayout(state.Page, createElement(DashboardView, null)) : ((matchValue.tag === 4) ? ((matchValue.fields[0].tag === 1) ? inLayout(state.Page, createElement(PortWindDashboardView, null)) : inLayout(state.Page, createElement(PortWindView, null))) : defaultOf()))))))))) : ((p_1_1 = ofList(map_1((value) => value, singleton(["text", "Loading ..."])), {
            Compare: comparePrimitives,
        }), createElement(LoadingScreenModule_View, {
            p: p_1_1,
        })))))),
    })), ["application", react.createElement(react.Fragment, {}, ...elements_1)])])));
}

