import { toString, Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { PortWindWeatherResult_$reflection, SensorId_$reflection, SensorMetaData_$reflection, PortWindWeather_$reflection } from "../StenaWeather.Domain/PortWind.js";
import { union_type, record_type, class_type, option_type, list_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Point_$reflection } from "../StenaWeather.Domain/Forecast.js";
import { length, toArray as toArray_2, collect, sortByDescending, head, isEmpty, singleton, ofArray, map, empty } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { ServerError_$reflection } from "../StenaWeather.WeatherServiceUI.Shared/Errors.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import PortWind$002Emodule from "../../src/StenaWeather.WeatherServiceUI.Client/Pages/PortWind.module.scss";
import { interpolate, toText, format, join } from "../fable_modules/fable-library-js.4.19.3/String.js";
import { FSharpMap__get_Item, ofSeq, ofList } from "../fable_modules/fable-library-js.4.19.3/Map.js";
import { createElement } from "react";
import React from "react";
import { compareArrays, comparePrimitives, createObj } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { DivsForStandardTable_dateTimeDiv, DivsForStandardTable_defaultDiv, rotateDirection180 } from "./Common.js";
import { IconModule_View } from "../Components/StenaWebUI/Icons/Icon.js";
import { faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";
import { reactApi } from "../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { SpaceModule_View } from "../Components/StenaWebUI/Core/Space.js";
import { RowModule_View } from "../Components/StenaWebUI/Core/Row.js";
import { IndentModule_View } from "../Components/StenaWebUI/Core/Indent.js";
import { ErrorView_Cmd_error, ErrorView_Cmd_ofError } from "../SharedView.js";
import { onPortWindAPI, Cmd_OfAsync_eitherAsResult } from "../Server.js";
import { fromHours } from "../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { compare } from "../fable_modules/fable-library-js.4.19.3/Date.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { React_useElmish_Z6C327F2E } from "../fable_modules/Feliz.UseElmish.2.5.0/UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../fable_modules/Fable.Elmish.4.2.0/program.fs.js";
import { toArray, map as map_1, defaultArg } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { defaultOf } from "../fable_modules/fable-library-js.4.19.3/Util.js";
import { TextModule_View } from "../Components/StenaWebUI/Core/Text.js";
import { TextVariant } from "../Components/StenaWebUI/Core/Text.js";
import { map as map_2, collect as collect_1, toArray as toArray_1, iterate, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library-js.4.19.3/Seq.js";
import { ColumnModule_View } from "../Components/StenaWebUI/Core/Column.js";
import { ButtonBase_ViewSecondary } from "../Components/StenaWebUI/Elements/Button.js";
import { StandardTableModule_view } from "../Components/StenaWebUI/Grid/StandardTable.js";

export class PortWindData extends Record {
    constructor(Weather, Location, LastUpdated) {
        super();
        this.Weather = Weather;
        this.Location = Location;
        this.LastUpdated = LastUpdated;
    }
}

export function PortWindData_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWind.PortWindData", [], PortWindData, () => [["Weather", list_type(PortWindWeather_$reflection())], ["Location", option_type(Point_$reflection())], ["LastUpdated", class_type("System.DateTimeOffset")]]);
}

export class State extends Record {
    constructor(Sensors, SelectedSensor, Weather) {
        super();
        this.Sensors = Sensors;
        this.SelectedSensor = SelectedSensor;
        this.Weather = Weather;
    }
}

export function State_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWind.State", [], State, () => [["Sensors", list_type(SensorMetaData_$reflection())], ["SelectedSensor", option_type(SensorId_$reflection())], ["Weather", option_type(PortWindData_$reflection())]]);
}

export function State_get_Empty() {
    return new State(empty(), undefined, undefined);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetSensors", "SensorsLoaded", "GetWeather", "WeatherLoaded"];
    }
}

export function Msg_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Client.Pages.PortWind.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SensorMetaData_$reflection()), ServerError_$reflection()], FSharpResult$2, () => [[["ResultValue", list_type(SensorMetaData_$reflection())]], [["ErrorValue", ServerError_$reflection()]]])]], [["Item", SensorId_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [PortWindWeatherResult_$reflection(), ServerError_$reflection()], FSharpResult$2, () => [[["ResultValue", PortWindWeatherResult_$reflection()]], [["ErrorValue", ServerError_$reflection()]]])]]]);
}

export const cn = (() => {
    const styles = PortWind$002Emodule;
    const cnFn = (name) => (styles[name]);
    const cnFns = (names) => map((x) => (styles[x]), names);
    return {
        className: cnFn,
        classes: cnFns,
        prop: {
            className: (arg) => ["className", cnFn(arg)],
            classes: (arg_1) => ["className", join(" ", cnFns(arg_1))],
        },
    };
})();

export function round(x) {
    return format('{0:' + "0" + '}', x);
}

function DivsForStandardTable_directionsDiv(unit, value) {
    let p_1_4, elems, t, p_1_2, p_1_3;
    const p_1_5 = ofList(map((value_9) => value_9, singleton(["children", singleton((p_1_4 = ofList(map((value_8) => value_8, singleton(["children", ofArray([createElement("div", createObj(ofArray([["style", {
        transform: ("rotate(" + ~~rotateDirection180(value)) + "deg)",
    }], (elems = [IconModule_View(ofList(map((value_4) => value_4, ofArray([(t = faLongArrowAltUp, ["icon", t]), ["color", "var(--lhds-color-ui-600)"]])), {
        Compare: comparePrimitives,
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), (p_1_2 = ofList(map((value_6) => value_6, empty()), {
        Compare: comparePrimitives,
    }), createElement(SpaceModule_View, {
        p: p_1_2,
    })), (p_1_3 = ofList(map((value_7) => value_7, empty()), {
        Compare: comparePrimitives,
    }), createElement(SpaceModule_View, {
        p: p_1_3,
    })), DivsForStandardTable_defaultDiv(`${round(value)}${unit}`)])])), {
        Compare: comparePrimitives,
    }), createElement(RowModule_View, {
        p: p_1_4,
    })))])), {
        Compare: comparePrimitives,
    });
    return createElement(IndentModule_View, {
        p: p_1_5,
    });
}

export function init() {
    return [State_get_Empty(), singleton((dispatch) => {
        dispatch(new Msg(0, []));
    })];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [state, ErrorView_Cmd_ofError(msg.fields[0].fields[0])];
            }
            else if (isEmpty(msg.fields[0].fields[0])) {
                return [state, ErrorView_Cmd_error("Sensor list is empty")];
            }
            else {
                return [new State(msg.fields[0].fields[0], state.SelectedSensor, state.Weather), singleton((dispatch) => {
                    dispatch(new Msg(2, [head(msg.fields[0].fields[0]).SensorId]));
                })];
            }
        case 2:
            return [new State(state.Sensors, msg.fields[0], undefined), Cmd_OfAsync_eitherAsResult(() => onPortWindAPI((x_1) => x_1.GetWeather(msg.fields[0], fromHours(3), fromHours(1))), (Item_1) => (new Msg(3, [Item_1])))];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [state, ErrorView_Cmd_ofError(msg.fields[0].fields[0])];
            }
            else {
                return [new State(state.Sensors, state.SelectedSensor, new PortWindData(sortByDescending((x_4) => x_4.DateTime, sortByDescending((x_2) => x_2.DateTime, msg.fields[0].fields[0].Weather, {
                    Compare: compare,
                }), {
                    Compare: compare,
                }), msg.fields[0].fields[0].Location, msg.fields[0].fields[0].LastUpdated)), Cmd_none()];
            }
        default:
            return [state, Cmd_OfAsync_eitherAsResult(() => onPortWindAPI((x) => x.GetSensors()), (Item) => (new Msg(1, [Item])))];
    }
}

export function PortWindView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const columns = ofArray([["Date", (w) => DivsForStandardTable_dateTimeDiv(w.DateTime)], ["Actual", (w_1) => defaultArg(map_1((x) => DivsForStandardTable_defaultDiv(`${round(x)} m/s`), w_1.WindSpeed), defaultOf())], ["Direction", (w_2) => defaultArg(map_1((arg) => DivsForStandardTable_directionsDiv("°", arg), w_2.WindDirection), defaultOf())], ["Average", (w_3) => defaultArg(map_1((x_1) => DivsForStandardTable_defaultDiv(`${round(x_1)} m/s`), w_3.WindSpeedAverage), createElement("div", {}))], ["Gust wind", (w_4) => defaultArg(map_1((x_2) => DivsForStandardTable_defaultDiv(`${round(x_2)} m/s`), w_4.GustWindSpeed), defaultOf())]]);
    const mkText = (s) => TextModule_View(ofList(map((value_10) => value_10, singleton(["children", s])), {
        Compare: comparePrimitives,
    }));
    const mkBoldText = (s_1) => TextModule_View(ofList(map((value_12) => value_12, ofArray([["variant", new TextVariant(3, [])], ["children", s_1]])), {
        Compare: comparePrimitives,
    }));
    const children_4 = toList(delay(() => {
        let p_1_6, p_1_3, p_1_5;
        return append(singleton_1((p_1_6 = ofList(map((value_17) => value_17, ofArray([["justifyContent", "space-between"], ["children", ofArray([(p_1_3 = ofList(map((value_14) => value_14, ofArray([["width", "50%"], ["children", singleton(createElement("h1", {
            children: ["PortWind"],
        }))]])), {
            Compare: comparePrimitives,
        }), createElement(ColumnModule_View, {
            p: p_1_3,
        })), (p_1_5 = ofList(map((value_16) => value_16, ofArray([["justifyContent", "flex-end"], ["children", singleton(ButtonBase_ViewSecondary(ofList(map((value_15) => value_15, ofArray([["label", "Refresh"], ["onClick", (_arg_2) => {
            iterate((sId) => {
                patternInput[1](new Msg(2, [sId]));
            }, toArray(state_1.SelectedSensor));
        }]])), {
            Compare: comparePrimitives,
        })))]])), {
            Compare: comparePrimitives,
        }), createElement(ColumnModule_View, {
            p: p_1_5,
        }))])]])), {
            Compare: comparePrimitives,
        }), createElement(RowModule_View, {
            p: p_1_6,
        }))), delay(() => {
            const lastUpdatedView = (lastUpdated) => {
                let p_1_7, p_1_8;
                const p_1_9 = ofList(map((value_20) => value_20, singleton(["children", ofArray([(p_1_7 = ofList(map((value_18) => value_18, singleton(["children", singleton(DivsForStandardTable_defaultDiv("Last updated: "))])), {
                    Compare: comparePrimitives,
                }), createElement(ColumnModule_View, {
                    p: p_1_7,
                })), (p_1_8 = ofList(map((value_19) => value_19, singleton(["children", singleton(DivsForStandardTable_dateTimeDiv(lastUpdated))])), {
                    Compare: comparePrimitives,
                }), createElement(ColumnModule_View, {
                    p: p_1_8,
                }))])])), {
                    Compare: comparePrimitives,
                });
                return createElement(RowModule_View, {
                    p: p_1_9,
                });
            };
            return append(singleton_1(createElement("h2", {
                children: ["Gothenburg - Majnabbe"],
            })), delay(() => {
                let children;
                const matchValue = state_1.Weather;
                let matchResult, weather_3, weather_4;
                if (matchValue != null) {
                    if (length(matchValue.Weather) === 0) {
                        matchResult = 0;
                        weather_3 = matchValue;
                    }
                    else if (length(matchValue.Weather) > 0) {
                        matchResult = 1;
                        weather_4 = matchValue;
                    }
                    else {
                        matchResult = 2;
                    }
                }
                else {
                    matchResult = 2;
                }
                switch (matchResult) {
                    case 0:
                        return append(singleton_1((children = singleton(mkBoldText("No data available!")), createElement("p", {
                            children: reactApi.Children.toArray(Array.from(children)),
                        }))), delay(() => singleton_1(lastUpdatedView(weather_3.LastUpdated))));
                    case 1: {
                        const data = ofSeq(collect((x_12) => map((tupledArg) => [[x_12.DateTime, tupledArg[0]], tupledArg[1](x_12)], columns), weather_4.Weather), {
                            Compare: compareArrays,
                        });
                        return append(singleton_1(lastUpdatedView(weather_4.LastUpdated)), delay(() => append(singleton_1(defaultArg(map_1((l) => {
                            const children_2 = ofArray([mkBoldText("Lat: "), mkText(toText(interpolate("%.3f%P()", [l.Latitude]))), mkBoldText(" Long: "), mkText(toText(interpolate("%.3f%P()", [l.Longitude])))]);
                            return createElement("p", {
                                children: reactApi.Children.toArray(Array.from(children_2)),
                            });
                        }, weather_4.Location), defaultOf())), delay(() => singleton_1(createElement("div", {
                            className: cn.className("table"),
                            children: StandardTableModule_view(createObj(toList([["items", toArray_1(toArray_2(weather_4.Weather))], ["variant", "standard"], ["config", createObj(toList([["keyResolver", (w_5) => toString(w_5.DateTime)], ["stickyHeader", true], ["stickyColumnGroups", "first"], ["disableSorting", true], ["columns", createObj(toList(delay(() => collect_1((matchValue_1) => {
                                let columnProps;
                                const columnName = matchValue_1[0];
                                return singleton_1((columnProps = createObj(toList([["name", `${columnName}`], ["itemValueResolver", (value_27) => {
                                }], ["columnLabel", `${columnName}`], ["renderCell", (p_18) => FSharpMap__get_Item(data, [p_18.item.DateTime, columnName])]])), [columnProps.name, columnProps]));
                            }, columns))))], ["columnOrder", toArray_1(map_2((tuple) => tuple[0], columns))]]))]]))),
                        }))))));
                    }
                    default:
                        return singleton_1(createElement("h3", {
                            children: ["Loading..."],
                        }));
                }
            }));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    });
}

