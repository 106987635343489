import { Dictionary } from "./fable_modules/fable-library-js.4.19.3/MutableMap.js";
import { HashIdentity_Structural } from "./fable_modules/fable-library-js.4.19.3/FSharp.Collections.js";
import { FSharpRef, toString } from "./fable_modules/fable-library-js.4.19.3/Types.js";
import { tryGetValue } from "./fable_modules/fable-library-js.4.19.3/MapUtil.js";

export function debounce() {
    let memoizations = new Dictionary([], HashIdentity_Structural());
    return (timeout) => ((fn) => ((value) => {
        const key = toString(fn);
        let matchValue;
        let outArg = 0;
        matchValue = [tryGetValue(memoizations, key, new FSharpRef(() => outArg, (v) => {
            outArg = (v | 0);
        })), outArg];
        if (matchValue[0]) {
            clearTimeout(matchValue[1]);
        }
        const timeoutId_1 = setTimeout(() => {
            memoizations.delete(key);
            fn(value);
        }, timeout) | 0;
        memoizations.set(key, timeoutId_1);
    }));
}

