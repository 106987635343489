import { Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { ModelKey_$reflection } from "../../StenaWeather.Domain/Models.js";
import { lambda_type, tuple_type, class_type, record_type, string_type, union_type, list_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ForecastSource_$reflection, PointForecast_$reflection, TimePoint_$reflection } from "../../StenaWeather.Domain/Forecast.js";
import { WeatherTypeModule_Parameter_$reflection } from "../../StenaWeather.Domain/Weather.js";

export class Response_SessionModelSetup extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Best", "Predefined"];
    }
}

export function Response_SessionModelSetup_$reflection() {
    return union_type("StenaWeather.WeatherServiceUI.Shared.SessionDetails.API.Response.SessionModelSetup", [], Response_SessionModelSetup, () => [[], [["Item", list_type(ModelKey_$reflection())]]]);
}

export class Response_RouteWaypoint extends Record {
    constructor(Reference, TimePoint) {
        super();
        this.Reference = Reference;
        this.TimePoint = TimePoint;
    }
}

export function Response_RouteWaypoint_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.SessionDetails.API.Response.RouteWaypoint", [], Response_RouteWaypoint, () => [["Reference", string_type], ["TimePoint", TimePoint_$reflection()]]);
}

export class Response_SessionParamModelSetup extends Record {
    constructor(Parameter, ModelSetup) {
        super();
        this.Parameter = Parameter;
        this.ModelSetup = ModelSetup;
    }
}

export function Response_SessionParamModelSetup_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.SessionDetails.API.Response.SessionParamModelSetup", [], Response_SessionParamModelSetup, () => [["Parameter", WeatherTypeModule_Parameter_$reflection()], ["ModelSetup", Response_SessionModelSetup_$reflection()]]);
}

export class Response_SessionState extends Record {
    constructor(Token, CreatedAt) {
        super();
        this.Token = Token;
        this.CreatedAt = CreatedAt;
    }
}

export function Response_SessionState_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.SessionDetails.API.Response.SessionState", [], Response_SessionState, () => [["Token", class_type("System.Guid")], ["CreatedAt", class_type("System.DateTimeOffset")]]);
}

export class Response_SessionDetail extends Record {
    constructor(VesselName, CreatedAt, Parameters, Waypoints, SessionStates) {
        super();
        this.VesselName = VesselName;
        this.CreatedAt = CreatedAt;
        this.Parameters = Parameters;
        this.Waypoints = Waypoints;
        this.SessionStates = SessionStates;
    }
}

export function Response_SessionDetail_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.SessionDetails.API.Response.SessionDetail", [], Response_SessionDetail, () => [["VesselName", string_type], ["CreatedAt", class_type("System.DateTimeOffset")], ["Parameters", list_type(Response_SessionParamModelSetup_$reflection())], ["Waypoints", list_type(Response_RouteWaypoint_$reflection())], ["SessionStates", list_type(Response_SessionState_$reflection())]]);
}

export class Response_SessionInterpolatedForecast extends Record {
    constructor(PointForecast, InterpolationInfo, Source) {
        super();
        this.PointForecast = PointForecast;
        this.InterpolationInfo = InterpolationInfo;
        this.Source = Source;
    }
}

export function Response_SessionInterpolatedForecast_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.SessionDetails.API.Response.SessionInterpolatedForecast", [], Response_SessionInterpolatedForecast, () => [["PointForecast", PointForecast_$reflection()], ["InterpolationInfo", string_type], ["Source", ForecastSource_$reflection()]]);
}

export class Response_RouteInterpolatedForecast extends Record {
    constructor(Token, Forecasts) {
        super();
        this.Token = Token;
        this.Forecasts = Forecasts;
    }
}

export function Response_RouteInterpolatedForecast_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.SessionDetails.API.Response.RouteInterpolatedForecast", [], Response_RouteInterpolatedForecast, () => [["Token", class_type("System.Guid")], ["Forecasts", list_type(tuple_type(Response_RouteWaypoint_$reflection(), list_type(Response_SessionInterpolatedForecast_$reflection())))]]);
}

export class SessionDetailsAPI extends Record {
    constructor(GetSessionDetails, GetSessionResponseDetail) {
        super();
        this.GetSessionDetails = GetSessionDetails;
        this.GetSessionResponseDetail = GetSessionResponseDetail;
    }
}

export function SessionDetailsAPI_$reflection() {
    return record_type("StenaWeather.WeatherServiceUI.Shared.SessionDetails.API.SessionDetailsAPI", [], SessionDetailsAPI, () => [["GetSessionDetails", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Response_SessionDetail_$reflection()]))], ["GetSessionResponseDetail", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [Response_RouteInterpolatedForecast_$reflection()]))]]);
}

export function SessionDetailsAPI_RouteBuilder(_arg, m) {
    return `/api/sessionDetails/${m}`;
}

