import React from "react";
import * as react from "react";
import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { CollapsibleContent, Collapsible } from "@stenajs-webui/panels";

export function CollapsibleModule_View(collapsibleModule_ViewInputProps) {
    const p = collapsibleModule_ViewInputProps.p;
    let props;
    const label = map((x) => x, FSharpMap__TryFind(p, "label"));
    props = {
        collapsed: map((x_1) => x_1, FSharpMap__TryFind(p, "collapsed")),
        label: label,
        onClick: map((x_2) => x_2, FSharpMap__TryFind(p, "onClick")),
    };
    const children = defaultArg(map((x_3) => x_3, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Collapsible, props, ...children);
}

export function CollapsibleContentModule_View(collapsibleContentModule_ViewInputProps) {
    const p = collapsibleContentModule_ViewInputProps.p;
    const props = {
        contentLeft: map((x) => x, FSharpMap__TryFind(p, "contentLeft")),
        contentRight: map((x_1) => x_1, FSharpMap__TryFind(p, "contentRight")),
    };
    const children = defaultArg(map((x_2) => x_2, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(CollapsibleContent, props, ...children);
}

