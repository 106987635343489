import { ofList } from "./fable_modules/fable-library-js.4.19.3/Map.js";
import { singleton, ofArray, map } from "./fable_modules/fable-library-js.4.19.3/List.js";
import { ServerErrorModule_text, ServerErrorModule_title } from "./StenaWeather.WeatherServiceUI.Shared/Errors.js";
import { comparePrimitives } from "./fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import { BannerModule_View } from "./Components/StenaWebUI/Elements/Banner.js";
import { Cmd_ofEffect } from "./fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { ToastModule_customToast } from "./Components/HotToast/Toast.js";
import { HeadingModule_View } from "./Components/StenaWebUI/Core/Heading.js";
import { reactApi } from "./fable_modules/Feliz.2.8.0/Interop.fs.js";

export function ErrorView_serverErrorToast(e) {
    const p_1_1 = ofList(map((value) => value, ofArray([["variant", "error"], ["headerText", ServerErrorModule_title(e)], ["text", ServerErrorModule_text(e)]])), {
        Compare: comparePrimitives,
    });
    return createElement(BannerModule_View, {
        p: p_1_1,
    });
}

export function ErrorView_errorToast(e) {
    const p_1_1 = ofList(map((value) => value, ofArray([["variant", "error"], ["text", e]])), {
        Compare: comparePrimitives,
    });
    return createElement(BannerModule_View, {
        p: p_1_1,
    });
}

export function ErrorView_Cmd_ofError(e) {
    return Cmd_ofEffect((_arg) => {
        ToastModule_customToast(ofList(map((value_2) => value_2, singleton(["children", singleton(ErrorView_serverErrorToast(e))])), {
            Compare: comparePrimitives,
        }));
    });
}

export function ErrorView_Cmd_error(e) {
    return Cmd_ofEffect((_arg) => {
        ToastModule_customToast(ofList(map((value_2) => value_2, singleton(["children", singleton(ErrorView_errorToast(e))])), {
            Compare: comparePrimitives,
        }));
    });
}

export function PageTitle_title(t, s) {
    let p_1_1, p_1_2;
    const children = ofArray([(p_1_1 = ofList(map((value_1) => value_1, ofArray([["variant", "h1"], ["children", singleton(t)]])), {
        Compare: comparePrimitives,
    }), createElement(HeadingModule_View, {
        p: p_1_1,
    })), (p_1_2 = ofList(map((value_3) => value_3, ofArray([["variant", "h3"], ["children", singleton(s)]])), {
        Compare: comparePrimitives,
    }), createElement(HeadingModule_View, {
        p: p_1_2,
    }))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

