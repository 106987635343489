import React from "react";
import * as react from "react";
import { defaultArg, map } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../../fable_modules/fable-library-js.4.19.3/Map.js";
import { empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Link } from "@stenajs-webui/elements";

export function LinkModule_View(linkModule_ViewInputProps) {
    const p = linkModule_ViewInputProps.p;
    const props = {
        href: map((x) => x, FSharpMap__TryFind(p, "href")),
        id: map((x_1) => x_1, FSharpMap__TryFind(p, "id")),
        onClick: map((x_2) => x_2, FSharpMap__TryFind(p, "onClick")),
    };
    const children = defaultArg(map((x_3) => x_3, FSharpMap__TryFind(p, "children")), empty());
    return react.createElement(Link, props, ...children);
}

