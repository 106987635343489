import { map } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpMap__TryFind } from "../../fable_modules/fable-library-js.4.19.3/Map.js";
import * as react from "react";
import { Circle, Line } from "rc-progress";
import { class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";

export function ProgressBarModule_ViewLinear(p) {
    let props;
    const percent = map((x) => x, FSharpMap__TryFind(p, "percent"));
    const strokeWidth = map((x_1) => x_1, FSharpMap__TryFind(p, "strokeWidth"));
    props = {
        percent: percent,
        strokeColor: map((x_2) => x_2, FSharpMap__TryFind(p, "strokeColor")),
        strokeWidth: strokeWidth,
    };
    return react.createElement(Line, props);
}

export function ProgressBarModule_ViewCircular(p) {
    let props;
    const percent = map((x) => x, FSharpMap__TryFind(p, "percent"));
    const strokeWidth = map((x_1) => x_1, FSharpMap__TryFind(p, "strokeWidth"));
    props = {
        percent: percent,
        strokeColor: map((x_2) => x_2, FSharpMap__TryFind(p, "strokeColor")),
        strokeWidth: strokeWidth,
    };
    return react.createElement(Circle, props);
}

export class ProgressBar {
    constructor() {
    }
}

export function ProgressBar_$reflection() {
    return class_type("StenaWeather.WeatherServiceUI.Client.Components.Rc_progress.ProgressBar", undefined, ProgressBar);
}

